<template>
    <div>
        <div class="rounded border bg-white">
            <div class="flex justify-between p-2 pr-4 handle">
                <div class="flex items-center space-x-4 truncate">
                    <div
                        class="flex justify-center items-center p-2 w-12 h-12 bg-gray-50 rounded border overflow-hidden">

                        <template v-if="$slots.empty_placeholder && !theSrc">
                            <slot name="empty_placeholder"></slot>
                        </template>

                        <template v-if="$slots.uploaded_placeholder && theSrc">
                            <slot name="uploaded_placeholder"></slot>
                        </template>

                        <template v-if="!$slots.empty_placeholder">
                            <img :src="theSrc" class="block rounded h-auto max-h-12"/>
                        </template>

                        <svg v-if="showSpinner" class="animate-spin h-8 w-8 absolute text-gray-500"
                             fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 24 24" width="24" height="24">
                            <path fill="none" d="M0 0h24v24H0z"/>
                            <path
                                d="M3.055 13H5.07a7.002 7.002 0 0 0 13.858 0h2.016a9.001 9.001 0 0 1-17.89 0zm0-2a9.001 9.001 0 0 1 17.89 0H18.93a7.002 7.002 0 0 0-13.858 0H3.055z"/>
                        </svg>
                    </div>
                    <span class="text-sm truncate">{{ title }}</span>
                </div>
                <div class="flex pl-4 space-x-4 items-center">
                    <button v-if="dynamicActionButtons ? deletePath : true" type="button" class="focus:outline-none" v-on:click="deleteFile()">
                        <svg class="w-5 h-5 text-gray-500 hover:text-red-600"
                             fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 24 24" width="24" height="24">
                            <path fill="none" d="M0 0h24v24H0z"></path>
                            <path
                                d="M17 6h5v2h-2v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8H2V6h5V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3zm1 2H6v12h12V8zm-9 3h2v6H9v-6zm4 0h2v6h-2v-6zM9 4v2h6V4H9z"></path>
                        </svg>
                    </button>
                    <label v-if="dynamicActionButtons ? !deletePath : true"
                        class="inline-flex group overflow-hidden relative justify-center items-center">
                        <input
                            class="block absolute opacity-0 cursor-pointer"
                            ref="file"
                            type="file"
                            :name="name"
                            :accept="accept"
                            v-on:change="uploadFile()"
                        >
                        <svg class="w-5 h-5 text-gray-500 group-hover:text-gray-900" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                            <path fill="none" d="M0 0h24v24H0z"/>
                            <path d="M4 19h16v-7h2v8a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-8h2v7zm9-10v7h-2V9H6l6-6 6 6h-5z"/>
                        </svg>
                    </label>
                    <template v-if="$slots.custom_actions">
                        <slot name="custom_actions"></slot>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    emits: ['upload:done', 'delete:done', 'upload:error'],
    props: ['src', 'no_src', 'path', 'accept', 'name', 'title', 'upload_url', 'delete_url', 'additional_data', 'dynamic_action_buttons'],
    data() {
        return {
            file: {},
            showSpinner: false,
            deletePath: this.path ?? null,
            dynamicActionButtons: this.dynamic_action_buttons ?? false,
            theSrc: this.src ?? this.no_src,
        }
    },
    methods: {
        deleteFile() {
            const self = this;
            this.showSpinner = true;

            axios
                .post(this.delete_url, {
                    name: this.name,
                    path: this.deletePath,
                    additional_data: JSON.stringify(this.additional_data ?? {})
                })
                .then(function () {
                    self.theSrc = self.no_src;
                    self.deletePath = null;
                    self.$emit('delete:done', true);
                })
                .catch(function (e) {
                    self.$emit('delete:done', false);
                })
                .finally(function () {
                    self.showSpinner = false;
                });
        },
        uploadFile() {

            if (this.$refs.file.files[0] === undefined) {
                return;
            }

            const self = this;
            this.showSpinner = true;
            const formData = new FormData();

            formData.append('name', this.name);
            formData.append(self.name, this.$refs.file.files[0]);
            formData.append('additional_data', JSON.stringify(this.additional_data ?? {}));

            axios
                .post(this.upload_url, formData, {headers: {'Content-Type': 'multipart/form-data'}})
                .then(function (response) {
                    const url = response?.data?.uploads?.[self.name]?.url ?? null;
                    const path = response?.data?.uploads?.[self.name]?.path ?? null;

                    if (url !== null) {
                        self.theSrc = url;
                    }

                    if (path !== null) {
                        self.deletePath = path;
                    }

                    self.$emit('upload:done', response?.data?.uploads || {});
                })
                .catch(function (error) {
                    self.$emit('upload:error', error.response?.data?.errors || { error: 'File upload error' });
                })
                .finally(function () {
                    self.showSpinner = false;
                    if (self.$refs.file && self.$refs.file.files && self.$refs.file.files[0]) {
                        self.$refs.file.files[0].value = null;
                    }
                });
        },
        refreshTheSrc() {
            if (this.src) {
                this.theSrc = this.src
            } else {
                this.theSrc = this.no_src
            }
        }
    }
}
</script>
