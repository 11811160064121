<template>

    <tooltip :content="tooltip" v-if="disabled">
        <toggle
            disabled="true"
            title="Enable multi-level affiliate commission"
        >
            <template #description>
                Allow partners to promote your affiliate program and earn rewards from their referrals' purchases.
            </template>
        </toggle>
    </tooltip>

    <toggle
        v-else
        :modelValue="enabled"
        @update:modelValue="updateEnabled"
        name="multi_tier_affiliate_commission_enabled"
        title="Enable multi-level affiliate commission">
        <template #description>
            <div>Allow partners to promote your affiliate program and earn rewards from their referrals' purchases.</div>
            <div class="mt-1.5 text-gray-900" v-show="enabled">
                <modal title="Multi-level commission" cancel_btn_title="Save">
                    <template #toggle>
                        <div class="flex items-center">
                            <div class="font-semibold mr-4">{{ commissionDescription }}</div>
                            <btn type="secondary" size="small">{{ buttonTitle }}</btn>
                        </div>
                    </template>
                    <template #body>
                        <div class="space-y-6">
                            <div class="mb-6 text-sm">
                                Choose how you want to reward partner from sales done from customers brought from recommended affiliates.
                            </div>
                            <div class="grid md:grid-cols-2 col-span-2 gap-6">
                                <div class="col-span-3">
                                    <label for="commission_type" class="block mb-1.5 text-sm">
                                        Commission type
                                    </label>
                                    <fieldset>
                                        <div class="-space-y-px bg-white rounded-md">
                                            <radio-group
                                                name="multi_tier_commissions_commission_type"
                                                id="multi_tier_commissions_commission_type"
                                                v-model="settings.commission_type"
                                                :options="[
                                                            { value: 'percent', label: 'Percentage', description: 'Reward your partners with a percentage of the sale amount.'},
                                                            { value: 'static', label: 'Static', description: 'Choose how to reward partners for sales made by affiliates they\'ve recruited.'},
                                                        ]"
                                                @update:modelValue="value => settings.commission_type = value"
                                            ></radio-group>
                                        </div>
                                    </fieldset>
                                </div>
                                <div>
                                    <label for="commission" class="block mb-1.5 text-sm">
                                        Commission and currency
                                    </label>
                                    <div class="flex relative rounded-md mb-1">
                                <span class="inline-flex items-center px-5 text-gray-500 bg-gray-50 rounded-l border border-r-0 border-gray-300 sm:text-sm" v-if="settings.commission_type === 'percent'">
                                    %
                                </span>
                                        <input
                                            type="text"
                                            name="multi_tier_commissions_commission_value"
                                            id="commission"
                                            placeholder="e.g. 10"
                                            v-model="settings.commission_value"
                                            class="block w-full rounded-r border-gray-300 transition-shadow sm:text-sm focus:ring-0 focus:border-gray-400"
                                        />
                                    </div>
                                    <small class="text-gray-500 mt-1 block">
                                        Set the commission amount and the currency.</small>
                                </div>
                                <div>
                                    <commission-period_setting
                                        :commission_type="settings.commission_period_type"
                                        :commission_times_value="settings.commission_period_times_value"
                                        select_name="multi_tier_commissions_commission_period_type"
                                        input_name="multi_tier_commissions_commission_period_times_value"

                                        @update:type="event => settings.commission_period_type = event.target.value"
                                        @update:value="value => settings.commission_period_times_value = value"
                                    ></commission-period_setting>
                                </div>
                            </div>

<!--                            <hr/>-->

<!--                            <toggle-->
<!--                                title="Show referral link to your affiliate program"-->
<!--                                name="multi_tier_affiliate_commission_show_referral_link"-->
<!--                                :model-value="show_referral_link"-->
<!--                                @update:modelValue="setReferralLinkValue"-->
<!--                            >-->
<!--                                <template #description>-->
<!--                                    Your partners will be able to see referral link to your affiliate program.-->
<!--                                </template>-->
<!--                            </toggle>-->
                        </div>
                    </template>
                </modal>
                <input type="hidden" name="multi_tier_affiliate_commission_show_referral_link" v-model="show_referral_link"/>
                <input type="hidden" name="multi_tier_affiliate_commission_type" v-model="settings.commission_type"/>
                <input type="hidden" name="multi_tier_affiliate_commission" v-model="settings.commission_value"/>
                <input type="hidden" name="multi_tier_affiliate_commission_period_type" v-model="settings.commission_period_type"/>
                <input type="hidden" name="multi_tier_affiliate_commission_period_times_value" v-model="settings.commission_period_times_value"/>
            </div>
        </template>
    </toggle>

</template>
<script setup>
import {ref, computed} from "vue";

const props = defineProps({
    multi_tier_affiliate_commission_enabled: {
        type: [Boolean, Number, String],
        default: false
    },
    multi_tier_affiliate_commission_show_referral_link: {
        type: [Boolean, Number, String],
        default: false
    },
    commissionSettings: {
        type: Object,
        default() {
            return {
                currency: '',
                setup_done: false,
                commission_value: 0,
                commission_type: 'static',
                commission_period_times_value: 1,
                commission_period_type: 'lifetime',
            };
        }
    },
    inputName: {
        type: String,
        required: true,
        default: 'multi_tier_affiliate_commission_enabled'
    },
    disabled: {
        type: Boolean,
        default: false
    },
    tooltip: {
        type: String,
        default: null
    }
});


const settings = ref(props.commissionSettings);
const enabled = ref(props.multi_tier_affiliate_commission_enabled);
const show_referral_link = ref(props.multi_tier_affiliate_commission_show_referral_link);
const setReferralLinkValue = (value) => show_referral_link.value = value ? 'on' : 'off';
const updateEnabled = (value) => enabled.value = value;

const commissionDescription = computed(() => {

    let description = '';

    if (!settings.value.setup_done) {
        return '';
    }

    let times = '';
    const currency = settings.value.currency;
    const type = settings.value.commission_type;
    const amount = settings.value.commission_value;
    const periodType = settings.value.commission_period_type;
    const periodTypeAmount = settings.value.commission_period_times_value;

    description += amount;
    description += (type === 'percent') ? '%' : ' ' + currency;

    if (periodType === 'lifetime') {
        description += ', lifetime';
    } else {
        if (periodType === 'times') {
            times = periodTypeAmount === 1 ? 'time' : 'times';
        } else {
            times = periodType;
        }
        description += ', ' + periodTypeAmount + ' ' + times;
    }

    return description;
});

const buttonTitle = computed(() => {

    if (commissionDescription.value.length > 3) {
        return 'Edit';
    }

    return 'Set up commission';
});

</script>

