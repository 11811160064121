<template>

    <div>

        <header-bar title="Apps & integrations" :description="'Improve your ' + program_type_name + ' program with these apps.'" size="xsmall"></header-bar>

        <div class="grid gap-8 xl:grid-cols-2">

            <div v-if="config.stripe" class="border rounded p-4">
                <integration-stripe
                    :integrations-config="config.stripe"
                    :program-id="parseInt(program_id)"
                    :syncCustomersAvailable="program_type === 'refer_a_friend'"
                ></integration-stripe>
            </div>

            <div v-if="config.paddle" class="border rounded p-4">
                <integration-paddle
                    :integrations-config="config.paddle"
                    :program-id="parseInt(program_id)"
                ></integration-paddle>
            </div>

            <div v-if="config.mailerlite" class="border rounded p-4">
                <integration-mailerlite :integrations_config="config.mailerlite"></integration-mailerlite>
            </div>

            <div v-if="config.paddle_classic" class="border rounded p-4">
                <integration-paddle-classic
                    :integrations-config="config.paddle_classic"
                    :program-id="parseInt(program_id)"
                ></integration-paddle-classic>
            </div>

            <div v-if="config.mailchimp" class="border rounded p-4">
                <integration-mailchimp :integrations_config="config.mailchimp"></integration-mailchimp>
            </div>

            <div v-if="config.paypal_mass_payout" class="border rounded p-4">
                <integration-paypal-mass-payout :integrations_config="config.paypal_mass_payout" :program_id="program_id"></integration-paypal-mass-payout>
            </div>

            <div v-if="config.wise_mass_payout" class="border rounded p-4">
                <integration-wise-mass-payout :integrations_config="config.wise_mass_payout" :program_id="program_id"></integration-wise-mass-payout>
            </div>

            <div v-if="config.shopify" class="border rounded p-4">
                <div class="flex items-start">
                    <div class="flex-shrink-0 w-10 h-10">
                        <img class="w-10 h-10 rounded"
                             src="/images/integrations/shopify.png"/>
                    </div>
                    <div class="ml-5">
                        <div class="flex justify-between">
                            <span class="text-sm font-semibold">Shopify</span>
                            <badge title="Connected" v-if="config.shopify.connected"></badge>
                        </div>
                        <div class="text-sm font-normal text-gray-500 mt-1 mb-2">
                            Introduce an affiliate program for your Shopify store with Partnero.
                        </div>
                        <div class="flex">
                            <modal :title="'Shopify integration'" size="large" name="shopify_integration">
                                <template #toggle>
                                    <btn type="secondary" size="small" class="-mr-px focus:z-10">Use</btn>
                                </template>
                                <template #body>
                                    <div class="space-y-4">
                                        <h3 class="text-lg font-medium">About</h3>
                                        <div class="text-gray-500 text-sm">Shopify is a complete commerce platform that lets you start, grow, and manage a business.
                                        </div>

                                        <hr/>
                                        <h3 class="text-lg font-medium">Integration status</h3>

                                        <div class="text-gray-500 text-sm">

                                            Your Shopify store and Partnero status is: <badge type="success" title="Active" v-if="config.shopify.app_installed"></badge><badge type="warning" title="Not installed" v-if="!config.shopify.app_installed"></badge>

                                        </div>

                                        <hr/>

                                        <h3 class="text-lg font-medium">Additional information</h3>
                                        <div class="text-gray-500 text-sm">
                                            <a data-beacon-article="55" class="underline">How to Get Started with Partnero on Shopify</a>
                                        </div>
                                    </div>
                                </template>
                            </modal>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="config.woocommerce" class="border rounded p-4">
                <div class="flex items-start">
                    <div class="flex-shrink-0 w-10 h-10">
                        <img class="w-10 h-10 rounded" src="/images/integrations/woocommerce.png"/>
                    </div>
                    <div class="ml-5">
                        <div class="flex justify-between">
                            <span class="text-sm font-semibold">WooCommerce</span>
                        </div>
                        <div class="text-sm font-normal text-gray-500 mt-1 mb-2">
                            Introduce an affiliate program for your WooCommerce store with Partnero.
                        </div>
                        <div class="flex">
                            <modal :title="'WooCommerce integration'" size="large" name="woocommerce_integration">
                                <template #toggle>
                                    <btn type="secondary" size="small" class="-mr-px focus:z-10">Use</btn>
                                </template>
                                <template #body>
                                    <div class="space-y-4">
                                        <h3 class="text-lg font-medium">About</h3>
                                        <div class="text-gray-500 text-sm">WooCommerce is the open-source ecommerce platform that helps merchants and developers build successful businesses for the long term.
                                            <a href="https://wordpress.org/plugins/partnero/" target="_blank" class="underline hover:no-underline">Partnero WooCommerce plugin</a> enables you to connect your WooCommerce store with Partnero.
                                        </div>
                                        <hr/>
                                        <h3 class="text-lg font-medium">Set-up</h3>
                                        <div class="text-gray-500 text-sm">
                                            <ol class="list-decimal ml-4 mb-4">
                                                <li>Download the <a href="https://wordpress.org/plugins/partnero/" target="_blank" class="underline hover:no-underline">Partnero Affiliate Management</a> plugin.</li>
                                                <li>Upload plugin files to the <span class="font-italic">/wp-content/plugins/partnero</span> directory.</li>
                                                <li>Activate the plugin through the <span class="font-semibold">Plugins</span> menu in WordPress.</li>
                                                <li>Click on Partnero on the right-hand side menu and enter your program API key. Click Connect.</li>
                                            </ol>
                                            <p>
                                                Your Partnero account has now been officially connected to your WooCommerce store.</p>
                                        </div>
                                        <h3 class="text-lg font-medium">Additional information</h3>
                                        <div class="text-gray-500 text-sm">
                                            <a href="#" data-beacon-article="208" class="underline">How to Get Started with Partnero on WooCommerce</a>
                                        </div>

                                    </div>
                                </template>
                            </modal>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="config.woocommerce" class="border rounded p-4">
                <integration-woocommerce
                    :integrations-config="config.woocommerce"
                    :program-id="parseInt(program_id)"
                ></integration-woocommerce>
            </div>

            <div class="border rounded p-4">
                <div class="flex items-start">
                    <div class="flex-shrink-0 w-10 h-10">
                        <img class="w-10 h-10 rounded" src="/images/integrations/zapier.png"/>
                    </div>
                    <div class="ml-5">
                        <div class="flex justify-between">
                            <span class="text-sm font-semibold">Zapier</span>
                        </div>
                        <div class="text-sm font-normal text-gray-500 mt-1 mb-2">
                            Connect Partnero with thousands of the most popular apps, and automate your work.
                        </div>
                        <div class="flex">
                            <modal :title="'Zapier integration'" size="large" name="zapier_integration">
                                <template #toggle>
                                    <btn type="secondary" size="small" class="-mr-px focus:z-10">Use</btn>
                                </template>
                                <template #body>
                                    <div class="space-y-4">
                                        <h3 class="text-lg font-medium">About</h3>
                                        <div class="text-gray-500 text-sm">Zapier lets you connect Partnero with thousands of the most popular apps,
                                            so you can automate your work and have more time for what matters most—no
                                            code required. Integrate with all the tools, applications, and services you
                                            need to manage your business.
                                        </div>
                                        <hr/>
                                        <h3 class="text-lg font-medium">Set-up</h3>
                                        <div class="text-gray-500 text-sm">
                                            <ol class="list-decimal ml-4 mb-4">
                                                <li>On Zapier, select which app you’d like to connect to your Partnero
                                                    account and follow the steps on the screen.
                                                </li>
                                                <li> When asked, authorize your account.</li>
                                            </ol>
                                            <p>
                                                Your Partnero account has now been officially connected to your Zapier
                                                account. You will also have to enter the API key of any other apps that
                                                you wish to connect to.</p>
                                        </div>
                                        <h3 class="text-lg font-medium">Additional information</h3>
                                        <div class="text-gray-500 text-sm">
                                            <a href="#" data-beacon-article="51" class="underline">How to Get Started with Partnero on Zapier</a>
                                        </div>

                                    </div>
                                </template>
                            </modal>
                        </div>
                    </div>
                </div>
            </div>

            <div class="border rounded p-4">
                <div class="flex items-start">
                    <div class="flex-shrink-0 w-10 h-10">
                        <img class="w-10 h-10 rounded" src="/images/integrations/make.png"/>
                    </div>
                    <div class="ml-5">
                        <div class="flex justify-between">
                            <span class="text-sm font-semibold">Make</span>
                        </div>
                        <div class="text-sm font-normal text-gray-500 mt-1 mb-2">
                            Connect Partnero with Make to effortlessly automate any task using a single, robust visual platform.
                        </div>
                        <div class="flex">
                            <modal :title="'Make integration'" size="large" name="zapier_integration">
                                <template #toggle>
                                    <btn type="secondary" size="small" class="-mr-px focus:z-10">Use</btn>
                                </template>
                                <template #body>
                                    <div class="space-y-4">
                                        <h3 class="text-lg font-medium">About</h3>
                                        <div class="text-gray-500 text-sm">Make is a visual platform that lets you design, build, and automate anything – from simple tasks to complex workflows — in minutes. With Make, you can send information between Partnero and thousands of apps to automate various tasks or get real-time notifications about what's happening with your partner program
                                        </div>
                                        <hr/>
                                        <h3 class="text-lg font-medium">Additional information</h3>
                                        <div class="text-gray-500 text-sm">
                                            <a href="#" data-beacon-article="53" class="underline">How to Get Started with Partnero on Make</a>
                                        </div>

                                    </div>
                                </template>
                            </modal>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="config.amazon_gift_card" class="border rounded p-4">
                <integration-amazon-gift-card :integrations_config="config.amazon_gift_card"></integration-amazon-gift-card>
            </div>

        </div>

    </div>

</template>
<script>
import IntegrationPaddle from './paddle/IntegrationPaddle'
import IntegrationPaddleClassic from "./paddle/IntegrationPaddleClassic.vue";

export default {
    name: 'Integrations',
    components: {
        IntegrationPaddleClassic,
        IntegrationPaddle
    },
    props: ['integrations_config', 'program_id', 'program_type', 'program_type_name'],
    data() {
        return {
            config: this.integrations_config
        }
    },
    methods: {}
}
</script>
<style scoped>
</style>
