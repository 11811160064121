<template>
    <div class="space-y-4">

        <div>
            <label class="block mb-1 text-sm">Additional links</label>
            <small class="text-gray-500 mt-1 ">Provide your affiliates with diverse promotional links. For example, you can include links to the pricing page, detailed feature pages, or even related external websites.</small>
        </div>

        <div>
            <ul class="text-sm space-y-2">
                <li v-for="link in props.additionalLinks">
                    <span class="flex gap-1 items-center font-medium text-sm text-teal-600 hover:underline">
                        <icon v-if="link.external" name="external-link" size="14"></icon>
                        <icon v-else name="link" size="14"></icon>
                        <span class="text-sm">{{ exampleUrl(link) }}</span>
                    </span>
                </li>
            </ul>
        </div>

        <modal size="large" title="Additional links">
            <template #toggle>
                <btn @click="handleModalOpen">Manage links</btn>
            </template>
            <template #body>
                <div
                    v-if="typeof saveErrors === 'string' || saveErrors instanceof String"
                    class="flex items-center bg-red-50 px-4 py-3 rounded text-red-500 text-sm gap-3 relative mb-5"
                >
                    {{ saveErrors }}
                </div>
                <div @keydown.enter.prevent="saveAdditionalLinks" class="space-y-6">
                    <div class="border rounded p-6">
                        <div class="mb-4">
                            <h3 class="text-sm font-semibold">Multiple links</h3>
                            <p class="text-sm text-gray-500 mt-1">Add multiple links to promote different pages of your website.</p>
                        </div>

                        <div class="divide-y">
                            <div v-for="(link, index) in internalLinks">
                                <div class="flex items-center justify-between gap-4 py-4">
                                    <div class="flex items-center gap-2">
                                        <div class="text-sm text-gray-500 font-semibold">{{ props.landingPageUrl }}/</div>
                                        <text-field
                                            v-model="link.url"
                                            placeholder="e.g. pricing"
                                            :error="!!saveErrors[`${LINK_TYPE_INTERNAL}.${index}.url`]"
                                            :class="{'order-last': link.prepend_ref_token && props.linkFormat === 'link'}"
                                        ></text-field>
                                        <div class="text-sm text-gray-500">
                                            <span class="inline-flex items-center rounded bg-gray-100 px-2 py-0.5 text-xs text-gray-800" v-show="props.linkFormat === 'query'">?{{ props.refLinkQueryParam }}=TOKEN</span>
                                            <span v-show="props.linkFormat === 'link'">{{ link.prepend_ref_token ? '' : '/' }}{{ props.refLinkQueryParam }}/TOKEN{{ link.prepend_ref_token ? '/' : '' }}</span>
                                        </div>
                                    </div>
                                    <div>
                                        <btn @click="link.prepend_ref_token = !link.prepend_ref_token" type="secondary" icon-name="arrow-swap" class="mr-2" v-show="props.linkFormat === 'link'"></btn>
                                        <btn @click="removeLink(LINK_TYPE_INTERNAL, index)" type="secondary" icon-name="delete"></btn>
                                    </div>
                                </div>
                                <span v-if="!!saveErrors[`${LINK_TYPE_INTERNAL}.${index}.url`]" class="flex block text-red-400 tracking-wide -mt-3 mb-4 text-xs">
                                    {{ saveErrors[`${LINK_TYPE_INTERNAL}.${index}.url`][0] }}
                                </span>
                            </div>

                        </div>

                        <btn @click="addLink(LINK_TYPE_INTERNAL)" type="secondary" size="small" icon-name="add-circle">
                            Add link
                        </btn>

                    </div>

                    <div class="border rounded p-6">

                        <div class="mb-4">
                            <h3 class="text-sm font-semibold">External links</h3>
                            <p class="text-sm text-gray-500 mt-1">Add external links to promote different website via same affiliate program.</p>
                            <alert type="neutral" class="mt-4"> Make sure new URLs has the tracking scripts installed.</alert>
                        </div>

                        <div class="divide-y">
                            <div v-for="(link, index) in externalLinks">
                                <div class="flex items-center justify-between gap-4 py-4">
                                    <div class="flex flex-1 items-center gap-2">
                                        <text-field
                                            v-model="link.url"
                                            class="w-1/2"
                                            placeholder="https://"
                                            :prefixes="['https://', 'http://']"
                                            :error="!!saveErrors[`${LINK_TYPE_EXTERNAL}.${index}.url`]"
                                        ></text-field>
                                        <div class="text-sm text-gray-500">
                                            <span class="inline-flex items-center rounded bg-gray-100 px-2 py-0.5 text-xs text-gray-800" v-show="props.linkFormat === 'query'">?{{ props.refLinkQueryParam }}=TOKEN</span>
                                            <span v-show="props.linkFormat === 'link'">/{{ props.refLinkQueryParam }}/TOKEN</span>
                                        </div>
                                    </div>
                                    <div>
                                        <btn @click="removeLink(LINK_TYPE_EXTERNAL, index)" type="secondary" icon-name="delete"></btn>
                                    </div>
                                </div>
                                <span v-if="!!saveErrors[`${LINK_TYPE_EXTERNAL}.${index}.url`]" class="flex block text-red-400 tracking-wide -mt-3 mb-4 text-xs">
                                    {{ saveErrors[`${LINK_TYPE_EXTERNAL}.${index}.url`][0] }}
                                </span>
                            </div>

                        </div>

                        <btn @click="addLink(LINK_TYPE_EXTERNAL)" type="secondary" size="small" icon-name="add-circle">
                            Add link
                        </btn>

                    </div>

                </div>
            </template>
            <template #footer>
                <btn @click="saveAdditionalLinks" :loading="saveLoading">Save</btn>
            </template>
        </modal>

    </div>
</template>

<script setup>
import {defineProps, onMounted, ref} from "vue";
import {rightTrimSlashes, trimSlashes} from "../../helpers/common";

const props = defineProps({
    landingPageUrl: String,
    refLinkQueryParam: String,
    linkFormat: String,
    additionalLinks: Array,
    saveUrl: String
})

const LINK_TYPE_INTERNAL = 'internalLinks'
const LINK_TYPE_EXTERNAL = 'externalLinks'

const linkSample = {
    url: '',
    prepend_ref_token: false
}

const internalLinks = ref([]);
const externalLinks = ref([]);
const saveErrors = ref([]);
const saveLoading = ref(false)

const addLink = (type) => {
    if (type === LINK_TYPE_INTERNAL) {
        internalLinks.value.push({...linkSample})
    } else if (type === LINK_TYPE_EXTERNAL) {
        externalLinks.value.push({...linkSample})
    }
}

const removeLink = (type, index) => {
    saveErrors.value = [];
    if (type === LINK_TYPE_INTERNAL) {
        internalLinks.value.splice(index, 1)
    } else if (type === LINK_TYPE_EXTERNAL) {
        externalLinks.value.splice(index, 1)
    }
}

const saveAdditionalLinks = () => {
    saveLoading.value = true
    saveErrors.value = []

    axios
        .post(props.saveUrl, {
            internalLinks: internalLinks.value,
            externalLinks: externalLinks.value
        })
        .then(response => {
            const status = parseInt(response.data.status || 0);

            if (status === 1) {
                window.location.reload();
            }

            if (status === 0) {
                saveErrors.value = response.data.errors || [];
            }
        })
        .catch(error => {
            saveErrors.value = error.response.data.errors
        })
        .finally(() => saveLoading.value = false);
}

const handleModalOpen = () => {
    internalLinks.value = props.additionalLinks.filter((link) => !link.external)
    externalLinks.value = props.additionalLinks.filter((link) => link.external)
    saveErrors.value = []
    saveLoading.value = false

    if (internalLinks.value.length === 0) {
        addLink(LINK_TYPE_INTERNAL)
    }
    if (externalLinks.value.length === 0) {
        addLink(LINK_TYPE_EXTERNAL)
    }
}
const exampleUrl = (link) => {
    if (link.external) {
        return rightTrimSlashes(link.url) + getRef()
    } else {
        return rightTrimSlashes(props.landingPageUrl)
            + (
                link.prepend_ref_token && props.linkFormat === 'link'
                    ? getRef() + '/' + trimSlashes(link.url)
                    : '/' + trimSlashes(link.url) + getRef()
            )
    }
}

const getRef = () => {
    if (props.linkFormat === 'query') {
        return '?' + props.refLinkQueryParam + '=TOKEN'
    } else if (props.linkFormat === 'link') {
        return '/' + props.refLinkQueryParam + '/TOKEN'
    }
}
</script>
