<template>
    <div class="bg-white rounded overflow-hidden border">
        <div class="flex justify-center items-center text-center py-20">
            <div v-if="modelName === 'partner'">
                <div class="flex justify-center items-center p-4">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                        <path fill="none" d="M0 0h24v24H0z"/>
                        <path
                            d="M1 22a8 8 0 1 1 16 0h-2a6 6 0 1 0-12 0H1zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zM21.548.784A13.942 13.942 0 0 1 23 7c0 2.233-.523 4.344-1.452 6.216l-1.645-1.196A11.955 11.955 0 0 0 21 7c0-1.792-.393-3.493-1.097-5.02L21.548.784zm-3.302 2.4A9.97 9.97 0 0 1 19 7a9.97 9.97 0 0 1-.754 3.816l-1.677-1.22A7.99 7.99 0 0 0 17 7a7.99 7.99 0 0 0-.43-2.596l1.676-1.22z"/>
                    </svg>
                </div>
                <h2 class="font-semibold text-lg mb-2">Partners live here</h2>
                <p class="text-gray-500 text-sm">When partners come (and they will!), you'll see them listed here.</p>
            </div>
            <div v-if="modelName === 'referral'">
                <div class="flex justify-center items-center p-4">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                        <path fill="none" d="M0 0h24v24H0z"/>
                        <path
                            d="M17.841 15.659l.176.177.178-.177a2.25 2.25 0 0 1 3.182 3.182l-3.36 3.359-3.358-3.359a2.25 2.25 0 0 1 3.182-3.182zM12 14v2a6 6 0 0 0-6 6H4a8 8 0 0 1 7.75-7.996L12 14zm0-13c3.315 0 6 2.685 6 6a5.998 5.998 0 0 1-5.775 5.996L12 13c-3.315 0-6-2.685-6-6a5.998 5.998 0 0 1 5.775-5.996L12 1zm0 2C9.79 3 8 4.79 8 7s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4z"/>
                    </svg>
                </div>
                <h2 class="font-semibold text-lg mb-2">Customers live here</h2>
                <p class="text-gray-500 text-sm">When referrals come (and they will!), you'll see them listed here.</p>
            </div>
            <div v-if="modelName === 'payouts'">
                <div class="flex justify-center items-center p-4">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                        <path fill="none" d="M0 0h24v24H0z"/>
                        <path
                            d="M14 2a8 8 0 0 1 3.292 15.293A8 8 0 1 1 6.706 6.707 8.003 8.003 0 0 1 14 2zm-4 6a6 6 0 1 0 0 12 6 6 0 0 0 0-12zm1 1v1h2v2H9a.5.5 0 0 0-.09.992L9 13h2a2.5 2.5 0 1 1 0 5v1H9v-1H7v-2h4a.5.5 0 0 0 .09-.992L11 15H9a2.5 2.5 0 1 1 0-5V9h2zm3-5a5.985 5.985 0 0 0-4.484 2.013 8 8 0 0 1 8.47 8.471A6 6 0 0 0 14 4z"/>
                    </svg>
                </div>
                <h2 class="font-semibold text-lg mb-2">Payout requests and history will be here</h2>
                <p class="text-gray-500 text-sm">When partners ask for payouts, you'll see them listed here.</p>
            </div>
            <div v-if="modelName === 'transaction'">
                <div>
                    <div class="flex justify-center items-center p-4">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                            <path fill="none" d="M0 0h24v24H0z"/>
                            <path
                                d="M19.375 15.103A8.001 8.001 0 0 0 8.03 5.053l-.992-1.737A9.996 9.996 0 0 1 17 3.34c4.49 2.592 6.21 8.142 4.117 12.77l1.342.774-4.165 2.214-.165-4.714 1.246.719zM4.625 8.897a8.001 8.001 0 0 0 11.345 10.05l.992 1.737A9.996 9.996 0 0 1 7 20.66C2.51 18.068.79 12.518 2.883 7.89L1.54 7.117l4.165-2.214.165 4.714-1.246-.719zM8.5 14H14a.5.5 0 1 0 0-1h-4a2.5 2.5 0 1 1 0-5h1V7h2v1h2.5v2H10a.5.5 0 1 0 0 1h4a2.5 2.5 0 1 1 0 5h-1v1h-2v-1H8.5v-2z"/>
                        </svg>
                    </div>
                    <h2 class="font-semibold text-lg mb-2">Transactions</h2>
                    <p class="text-gray-500 text-sm">Once customer will make a transaction, you'll see them listed
                        here.</p>
                </div>
            </div>
            <div v-if="modelName === 'subscriber'">
                <div>
                    <div class="flex justify-center items-center p-4">
                        <icon name="user-heart" size="24"></icon>
                    </div>
                    <h2 class="font-semibold text-lg mb-2">Subscribers live here</h2>
                    <p class="text-gray-500 text-sm">When referred subscribers come (and they will!), you'll see them listed
                        here.</p>
                </div>
            </div>
            <div v-if="modelName === 'promotionCodes'">
                <div>
                    <div class="flex justify-center items-center p-4">
                        <icon name="coupon" size="24"></icon>
                    </div>
                    <h2 class="font-semibold text-lg mb-2">Promotion codes</h2>
                    <p class="text-gray-500 text-sm">There are no available promotion codes yet.</p>
                </div>
            </div>
            <div v-if="modelName === 'coupon'">
                <div>
                    <div class="flex justify-center items-center p-4">
                        <icon name="coupon" size="24" class="mb-4"></icon>
                    </div>
                    <h2 class="font-semibold text-lg mb-2">There are no coupons yet</h2>
                    <p class="text-gray-500 text-sm">Create and manage coupons with ease. Coupons can provide an extra incentive for referred customers <br/>and offer a range of discount options to enhance their experience.</p>
                </div>
            </div>
            <div v-if="modelName === 'intermediary-payouts'">
                <div>
                    <div class="flex justify-center items-center p-4">
                        <icon name="coupon" size="24" class="mb-4"></icon>
                    </div>
                    <h2 class="font-semibold text-lg mb-2">There are no scheduled payouts yet</h2>
                    <p class="text-gray-500 text-sm">Once the first payout is scheduled, all relevant details will be displayed here.</p>
                </div>
            </div>
            <div v-if="modelName === 'logs'">
                <div>
                    <div class="flex justify-center items-center p-4">
                        <icon name="log" size="24" class="mb-4"></icon>
                    </div>
                    <h2 class="font-semibold text-lg mb-2">There are no logs yet</h2>
                    <p class="text-gray-500 text-sm">All relevant integration error details will be displayed in this section for easy reference.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "NoDataTable",
    props: {
        modelName: {
            type: String,
            required: true
        }
    }
}
</script>

<style scoped>

</style>
