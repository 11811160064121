<template>
    <div>

        <div class="p-4 bg-gray-50 bg-opacity-75 border-t space-y-4">
            <div class="grid lg:grid-cols-2 gap-8 gap-y-6">
                <div>
                    <FileUpload
                        :data="data.files.banner_image"
                        :options="options"
                        :unique_id="unique_id"
                        accept="image/*"
                        title="Image"
                        name="banner_image"
                        no_src="https://via.placeholder.com/300x100/E5E7EB/9CA3AF?text=img"
                        :allow_external_url="!data.files.banner_image.url && !data.files.banner_image.path"
                    ></FileUpload>
                </div>
                <div>
                    <FileUpload
                        :data="data.files.banner_content"
                        :options="options"
                        :unique_id="unique_id"
                        accept=".zip, .pdf"
                        title="Zip or PDF"
                        name="banner_content"
                    >
                        <template v-slot:empty_placeholder>
                            <div class="">
                                <span class="text-sm text-gray-500 hidden">No file</span>
                                <icon name="file-missing" size="24" />
                            </div>
                        </template>

                        <template v-slot:uploaded_placeholder>
                            <div class="">
                                <span class="text-sm text-gray-500 hidden">File present</span>
                                <icon name="file" size="24" />
                            </div>
                        </template>
                    </FileUpload>
                </div>
                <div class="col-span-2">
                    <label class="block mb-1.5 text-sm"> Title </label>
                    <input
                        type="text"
                        v-model="data.title"
                        class="block w-full text-sm rounded border-gray-300 focus:border-gray-400 focus:ring-0"/>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import FileUpload from './includes/FileUpload'
import Icon from "../../../../elements/Icon.vue";

export default {
    props: ['unique_id', 'data', 'options'],
    components: {
        Icon,
        FileUpload
    },
    created() {
        this.data.files = this.data?.files ?? {};
        this.data.files.banner_image = this.data.files.banner_image ?? {};
        this.data.files.banner_content = this.data.files.banner_content ?? {};
    }
}
</script>

<style lang="scss"></style>
