<script setup>

import RedemptionTimesPeriod from "./settings/RedemptionTimesPeriod.vue";
import TextField from "../../../../elements/forms/TextField.vue";
import Spinner from "../../../../elements/Spinner.vue";
import CouponDuration from "./settings/CouponDuration.vue";
import PromoCodesForm from "./settings/PromoCodesForm.vue";
import Btn from "../../../../elements/Btn.vue";
import FormSection from "../../../../elements/forms/FormSection.vue";
import RedirectToPromoCodeLandingSection from "./settings/RedirectToPromoCodeLandingSection.vue";
import RedemptionSpecificDatePeriod from "./settings/RedemptionSpecificDatePeriod.vue";
import {computed, onMounted, reactive, ref} from "vue";
import Notification from "../../../../elements/Notification.vue";
import {useCouponStore} from "../../../../stores/app/programs/common/coupons/CouponStore";


const props = defineProps(
    {
        couponData: [String, Object, null],
        couponAction: [String],
        program: Object,
        syncProvider: [String, null],
    }
)

const editing = props.couponAction === 'edit';

const isReferAFriendProgram = props.program?.program_type?.type === 'refer_a_friend'
const isNewsletterProgram = props.program?.program_type?.type === 'newsletter'

const couponStore = useCouponStore();

onMounted(() => {
    couponStore.fillFormDataFromCouponData(props.couponData)
    couponStore.syncProvider = props.syncProvider
});

const updatingCouponName = ref(false);
const updatingCouponMetadata = ref(false);

const jsonMetadata = computed(() => {
    return JSON.stringify(couponStore.formData.metadata)
})

const emits = defineEmits(['couponUpdated']);

const addMetadata = () => {
    couponStore.formData.metadata.push({ key: '', value: '' });
}

const removeMetadata = (metadataIndex) => {
    couponStore.formData.metadata.splice(metadataIndex, 1);
}

const updateCouponName = () => {
    updatingCouponName.value = true;
    axios.patch(route('app_int.coupon.update', {program: props.program, coupon: couponStore.formData.id}), {
        id: couponStore.formData.id,
        name: couponStore.formData.name,
    }).then(response => {
        const status = parseInt(response.data.status || '-1');
        if (status === 1) {
            couponStore.showNotification(true, 'Done', 'success', response.data.message);
        } else {
            couponStore.showNotification(true, 'Error', 'warning', response.data?.message ?? 'Something went wrong');
        }
    }).catch(error => {
        couponStore.showNotification(true, 'Error', 'warning', error.response?.data?.message ?? 'Something went wrong');
        console.log(error)
    }).finally(() => {
        updatingCouponName.value = false;
    })
}

const updateCouponMetadata = () => {
    updatingCouponMetadata.value = true;
    axios.patch(route('app_int.coupon.update', {program: props.program, coupon: couponStore.formData.id}), {
        id: couponStore.formData.id,
        metadata: couponStore.formData.metadata,
    }).then(response => {
        const status = parseInt(response.data.status || '-1');
        if (status === 1) {
            couponStore.showNotification(true, 'Done', 'success', response.data.message);
        } else {
            couponStore.showNotification(true, 'Error', 'warning', response.data?.message ?? 'Something went wrong')
        }
    }).catch(error => {
        couponStore.showNotification(true, 'Error', 'warning', error.response?.data?.message ?? 'Something went wrong');
        console.log(error)
    }).finally(() => {
        updatingCouponMetadata.value = false;
    })
}

</script>

<template>
    <div class="divide-y">

        <form-section title="Coupon" description="Set up the main details of the coupon.">

            <slot name="prepend-form-section"></slot>

            <text-field
                label="Coupon name"
                field-id="name"
                name="name"
                type="text"
                autofocus="true"
                v-model="couponStore.formData.name"
                placeholder="E.g. First purchase discount"
            >
                <template #append v-if="couponAction === 'edit'">
                    <btn type="primary"
                         @click="updateCouponName"
                         class="whitespace-nowrap ml-2"
                         :disabled="updatingCouponName"
                    >
                        <spinner
                            v-if="updatingCouponName"
                            :is-white="true"
                            class="mr-1.5"
                        />
                        {{ updatingCouponName ? 'Updating...' : 'Update name' }}
                    </btn>
                </template>
                <template #note>
                    The name of the coupon can be displayed to your customers through your application.
                </template>
            </text-field>
            <text-field
                v-if="couponStore.syncProvider !== 'shopify'"
                label="Internal name"
                field-id="uuid_code"
                name="uuid_code"
                type="text"
                v-model="couponStore.formData.uuid_code"
                :disabled="editing"
            >
                <template #note>
                    This will identify the coupon internally within Partnero or Partnero API. You can leave this blank, and we will generate a UUID for you.
                </template>
            </text-field>
        </form-section>

        <form-section
            title="Coupon type"
            description="Determine whether the coupon provides a fixed amount or a percentage discount."
        >
            <div class="grid grid-cols-2 gap-8">
                <div class="col-span-2">
                    <radio-group
                        label="Coupon type"
                        name="coupon_discount_type"
                        v-model="couponStore.formData.coupon_discount_type"
                        :options="[
                            { value: 'percent', label: 'Percentage', description: 'Add coupon discount with a percentage amount.', disabled: editing},
                            { value: 'static', label: 'Static', description: 'Add coupon discount with a fixed amount.', disabled: editing},
                        ]"

                    />
                </div>

                <text-field
                    :label="couponStore.formData.coupon_discount_type === 'percent' ? 'Percentage off' : 'Discount amount'"
                    field-id="coupon_discount_type"
                    name="coupon_discount_amount"
                    type="text"
                    :placeholder="couponStore.formData.coupon_discount_type === 'percent' ? 'e.g. 10' : 'e.g. 10.00'"
                    :prepend="couponStore.formData.coupon_discount_type === 'percent' ? '%' : null"
                    v-model="couponStore.formData.coupon_discount_amount"
                    :disabled="editing"
                />
                <coupon-duration
                    name="Coupon duration"
                    description="Set up for how long the coupon is valid for."
                    select_name="coupon_duration_type"
                    input_name="coupon_duration_value"
                    :coupon_duration_type="couponStore.formData.coupon_duration_type"
                    :coupon_duration_value="couponStore.formData.coupon_duration_value"
                    :syncProvider="couponStore.syncProvider"
                    :disabled="editing"
                />

            </div>
        </form-section>

        <form-section
            title="Additional settings"
            description="Fine-tune the coupon."
        >
            <div class="grid grid-cols-2 gap-8">


                <div class="col-span-2">
                    <div class="text-sm font-semibold bg-gray-50 rounded-t border p-4">Redemption limits</div>
                    <div class="grid gap-y-6 gap-8 md:grid-cols-2 border rounded-b border-t-0 p-4">
                        <redemption-specific-date-period
                            id="spec-date-period-coupon"
                            name="Specific date"
                            description="Limit the date range when customers can redeem this coupon."
                            select_name="redemption_specific_date_status"
                            input_name="redemption_specific_date_value"
                            v-model:type="couponStore.formData.redemption_specific_date_status"
                            v-model:redemption_value="couponStore.formData.redemption_specific_date_value"
                            :disabled="editing"
                        />
                        <redemption-times-period
                            name="Times"
                            description="Limit the total number of times this coupon can be redeemed."
                            select_name="redemption_times_status"
                            input_name="redemption_times_value"
                            v-model:type="couponStore.formData.redemption_times_status"
                            v-model:redemption_value="couponStore.formData.redemption_times_value"
                            :disabled="editing"
                        />
                    </div>
                </div>

                <div class="col-span-2 space-y-4">
                    <div v-if="couponStore.formData.metadata.length">
                        <div class="text-sm font-semibold bg-gray-50 rounded-t border p-4">Metadata</div>
                        <div class="border rounded-b border-t-0 p-4 space-y-4">
                            <div v-for="(metadata, metadataIndex) in couponStore.formData.metadata"
                                 :key="'coupon-metadata-' + metadataIndex"
                                 class="grid grid-cols-5 gap-4"
                            >
                                <text-field
                                    class="md:col-span-2"
                                    type="text"
                                    v-model="metadata.key"
                                    placeholder="Key"
                                >
                                </text-field>
                                <text-field
                                    class="col-span-3"
                                    type="text"
                                    v-model="metadata.value"
                                    placeholder="Value"
                                >
                                    <template #append>
                                        <btn type="secondary" icon-name="trash"
                                             class="ml-2"
                                             @click="removeMetadata(metadataIndex)"></btn>
                                    </template>
                                </text-field>
                            </div>
                        </div>
                    </div>
                    <input type="text" hidden name="metadata" v-model="jsonMetadata">
                    <div>
                        <btn type="secondary"
                             @click="addMetadata()"
                             size="small"
                        >
                            Add metadata
                        </btn>
                        <div v-if="editing" class="flex justify-end px-6">
                            <btn type="primary"
                                 @click="updateCouponMetadata()"
                                 class="whitespace-nowrap ml-2"
                                 :disabled="updatingCouponMetadata"
                            >
                                <spinner
                                    v-if="updatingCouponMetadata"
                                    :is-white="true"
                                    class="mr-1.5"
                                />
                                {{ updatingCouponMetadata ? 'Updating...' : 'Update metadata' }}
                            </btn>
                        </div>
                    </div>
                </div>

            </div>
        </form-section>

        <form-section
            title="Promotion codes"
            :description="(couponAction === 'create' ? 'Create ' : 'View ') + 'customer-facing promotion codes for the coupon.'"
        >

            <PromoCodesForm v-if="couponAction === 'create'"
                            :coupon-id="couponData.id"
                            :coupon-name="couponStore.formData.name"
                            :coupon-discount-amount="couponStore.formData.coupon_discount_amount"
                            :program="program"
                            :coupon-action="couponAction"
            />

            <RedirectToPromoCodeLandingSection
                v-else
                :coupon-id="couponData.id"
                :program="program"
                :promo-codes-count="couponData.promotion_codes_count"
            />

            <toggle v-if="(isReferAFriendProgram || isNewsletterProgram) && couponStore.getShowRewardCouponAsOption && (couponStore.getPromotionCodes.length === 0)"
                v-model="couponStore.formData.is_reward_coupon"
                name="is_reward_coupon"
                title="Enable dynamic promotion codes"
                :true-value="true"
            >
                <template #description>
                    If enabled, you will have to declare the promotion code on which template the reward coupon will be generated and assigned to a customer.
                </template>
            </toggle>

        </form-section>

        <notification
            :show="couponStore.notification.showNotification"
            :title="couponStore.notification.notificationTitle"
            :type="couponStore.notification.notificationType"
            class="mb-20"
            @notificationClosed="couponStore.notification.showNotification = false"
            whitespace="whitespace-normal"
        >
            <template #message>
                {{ couponStore.notification.notificationMessage }}
            </template>
        </notification>
    </div>
</template>

<style scoped>

</style>
