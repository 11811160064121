<template>
    <div class="col-span-2 space-y-6">

        <text-field
            label="URL"
            name="portal_signup_tos_url"
            placeholder="https://"
            type="text"
            :model-value="props.tosUrl">
            <template #note>
                Once URL is entered, a checkbox will appear on your signup form.
            </template>
        </text-field>


        <div class="space-y-4">
            <div>
                <div class="flex justify-between items-center">
                    <toggle
                        name="portal_signup_tos_saved_locally"
                        size="default"
                        v-model="tosSaveModel"
                    ></toggle>
                    <span class="flex flex-col flex-grow">
                        <span class="text-sm font-medium text-gray-900">Save program terms on Partnero</span>
                        <span class="text-sm text-gray-500">Save your program terms on Partnero and edit them whenever necessary.</span>
                    </span>
                </div>
            </div>

            <div v-if="tosSaveModel">
                <label class="block mb-1.5 text-sm">Program terms</label>
                <text-editor name="portal_signup_tos_text" :text="props.tosText"></text-editor>
            </div>
        </div>


    </div>
</template>

<script setup>
import {ref} from "vue";

const props = defineProps({
    tosUrl: String,
    tosSave: String,
    tosText: String
})

const tosSaveModel = ref(!!props.tosSave)
</script>
