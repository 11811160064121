<template>
    <div class="px-10 divide-y divide-gray-200">

        <div class="grid gap-12 py-14 xl:grid-cols-3">
            <div class="space-y-2">
                <h3 class="text-lg font-medium">
                    Dashboard
                </h3>
                <p class="text-sm text-gray-500">Main dashboard content blocks visible upon login.</p>
            </div>
            <div class="grid col-span-2 gap-y-4 gap-8">

                <div>
                    <!-- Static content, default and always visible - Link sharing and affiliate program -->
                    <div class="bg-white rounded border mb-2">
                        <div class="flex justify-between p-4 handle">
                            <div class="flex items-center space-x-3 truncate">
                                <div class="focus:outline-none">
                                    <svg
                                        class="w-5 h-5 text-gray-300" fill="none"
                                        stroke="currentColor" viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                              d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                                        />
                                    </svg>
                                </div>
                                <svg
                                    class="w-5 h-5 text-gray-900" fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24"
                                    height="24"
                                >
                                    <path d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92s-1.31-2.92-2.92-2.92z"/>
                                </svg>
                                <span class="text-gray-500 text-sm truncate">Link sharing and affiliate data</span>
                            </div>
                            <div class="flex pl-6 space-x-4">
                            </div>
                        </div>
                    </div>
                    <!-- ./ Static content, default and always visible - Link sharing and affiliate program -->
                    <draggable
                        class="space-y-2 overflow-auto"
                        v-model="dashboardResources"
                        handle=".handle"
                        group="dashboardResources"
                        @start="drag=true"
                        direction="vertical"
                        chosenClass="bg-gray-100"
                        @end="drag=false"
                        item-key="$index"
                    >
                        <template #item="{element}">
                            <DashboardRes
                                :data="element"
                                :options="options"
                                :unique_id="element.unique_id ?? uniqueId()"
                                @remove="removeResource"
                            />
                        </template>
                    </draggable>

                    <div v-if="fetchingResources"
                        class="border border rounded p-4 w-full">
                        <div class="animate-pulse flex flex-col space-y-4">
                            <div class="border border-gray-200 flex p-3 rounded space-x-4 w-full">
                                <div class="rounded bg-gray-200 h-7 w-7"></div>
                                <div class="flex-1 space-y-6 py-1">
                                    <div class="space-y-3">
                                        <div class="grid grid-cols-3 gap-4">
                                            <div class="h-2 bg-gray-200 rounded col-span-2"></div>
                                            <div class="h-2 bg-gray-200 rounded col-span-1"></div>
                                        </div>
                                        <div class="h-2 bg-gray-200 rounded"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="border border-gray-200 flex p-3 rounded space-x-4 w-full">
                                <div class="rounded bg-gray-200 h-7 w-7"></div>
                                <div class="flex-1 space-y-6 py-1">
                                    <div class="space-y-3">
                                        <div class="grid grid-cols-3 gap-4">
                                            <div class="h-2 bg-gray-200 rounded col-span-2"></div>
                                            <div class="h-2 bg-gray-200 rounded col-span-1"></div>
                                        </div>
                                        <div class="h-2 bg-gray-200 rounded"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="border border-gray-200 flex p-3 rounded space-x-4 w-full">
                                <div class="rounded bg-gray-200 h-7 w-7"></div>
                                <div class="flex-1 space-y-6 py-1">
                                    <div class="space-y-3">
                                        <div class="grid grid-cols-3 gap-4">
                                            <div class="h-2 bg-gray-200 rounded col-span-2"></div>
                                            <div class="h-2 bg-gray-200 rounded col-span-1"></div>
                                        </div>
                                        <div class="h-2 bg-gray-200 rounded"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="!dashboardResources.length && !fetchingResources" class="rounded border">
                        <div class="flex justify-center items-center text-center p-20">
                            <div>
                                <div class="flex justify-center items-center p-4">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M21 4H7a2 2 0 1 0 0 4h14v13a1 1 0 0 1-1 1H7a4 4 0 0 1-4-4V6a4 4 0 0 1 4-4h13a1 1 0 0 1 1 1v1zM5 18a2 2 0 0 0 2 2h12V10H7a3.982 3.982 0 0 1-2-.535V18zM20 7H7a1 1 0 1 1 0-2h13v2z"/></svg>
                                </div>
                                <h2 class="font-semibold text-lg mb-2">{{ this.zeroFieldsTitle }}</h2>
                                <p class="text-gray-500 text-sm">{{ this.zeroFieldsMessage }}</p>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="w-max mt-4">
                    <options :is-top="true" :is-right="false">
                        <template #toggle>
                            <btn type="secondary" v-on:click.prevent>Add resource</btn>
                        </template>
                        <template v-slot:items="{ setShow }">
                            <options-item @click="addResource('dashboardRecentActivity');setShow(false)" title="Recent activity"></options-item>
                            <options-item @click="addResource('dashboardTextBlock');setShow(false)" title="Text block"></options-item>
                            <options-item @click="addResource('dashboardContentBlock');setShow(false)" title="Content block"></options-item>
                            <options-item @click="addResource('dashboardLinks');setShow(false)" title="Links"></options-item>
                        </template>
                    </options>
                </div>

                <input type="hidden" :name="name" :value="fieldsString">
            </div>
        </div>
    </div>

    <div class="bg-gray-50 px-10 py-4 text-right rounded-b sticky bottom-0 z-40 col-span-3">
                <btn
                    button-type="submit"
                    type="primary"
                    :disabled="updatingResources"
                    @click.prevent="saveResources()"
                >
                    <spinner
                        v-if="updatingResources"
                        :is-white="true"
                        class="mr-1.5"
                    />
                    {{ updatingResources ? 'Updating...' : 'Update' }}
                </btn>
            </div>
        <notification
            :show="showNotification"
            :title="notificationTitle"
            :type="notificationType"
            class="mb-20"
            @notificationClosed="this.showNotification = false"
        >
            <template #message>
                {{ notificationMessage }}
            </template>
        </notification>

</template>

<script>
import {v4 as uuid} from "uuid";
import draggable from 'vuedraggable'
import Res from "../../../resources/Res";
import DashboardRes from "./DashboardRes";
import Spinner from "../../../../../elements/Spinner";

export default {
    props: ['name', 'zeroFieldsMessage','zeroFieldsTitle', 'get_resource_url', 'update_url', 'file_upload_url', 'file_delete_url'],

    components: {
        DashboardRes,
        Res,
        draggable,
        Spinner
    },

    computed: {
        fieldsString() {
            return JSON.stringify(this.dashboardResources);
        },
        dashboardRecentActivityAlreadyIncluded() {
            return this.dashboardResources.some(resource => resource.type === 'dashboardRecentActivity')
        },
    },

    data() {
        return {
            dashboardResources: [],
            showNotification: false,
            notificationType: '',
            notificationTitle: '',
            notificationMessage: '',
            fetchingResources: false,
            updatingResources: false,
            options: {
                'update_url': this.update_url,
                'file_delete_url': this.file_delete_url,
                'file_upload_url': this.file_upload_url
            },
            drag: false,
        }
    },
    created() {
        this.fetchResources()
    },
    methods: {
        fetchResources() {
            this.fetchingResources = true
            axios
                .get(this.get_resource_url)
                .then(response => {
                    this.dashboardResources = response.data
                    this.fetchingResources = false
                })
                .catch(error => {
                })
                .finally(() => {
                    this.fetchingResources = false
                });
        },
        saveResources() {
            this.updatingResources = true
            axios
                .post(this.update_url, {dashboardResources: this.fieldsString})
                .then(response => {
                    if (response.status === 200) {
                        this.showNotification = true
                        this.notificationTitle = 'Done'
                        this.notificationType = 'success'
                        this.notificationMessage = 'Dashboard resource updated successfully'
                        this.updatingResources = false
                    }
                })
                .catch(error => {
                    this.showNotification = true
                    this.notificationTitle = 'Error'
                    this.notificationType = 'warning'
                    this.notificationMessage = 'Dashboard resource update error, see console for error details.'
                })
                .finally(() => {
                    this.updatingResources = false
                });
        },
        addResource(type) {
            if (type === 'dashboardRecentActivity' && this.dashboardRecentActivityAlreadyIncluded) {
                this.showNotification = true
                this.notificationTitle = 'Warning'
                this.notificationType = 'warning'
                this.notificationMessage = 'You can set only one dashboard recent activity.'
            } else {
                this.dashboardResources.push({
                    type,
                    edit: !['dashboardRecentActivity'].includes(type)
                })
            }
        },
        removeResource(resource) {
            this.dashboardResources = this.dashboardResources.filter((item) => item !== resource)
        },
        uniqueId() {
            return 'a' + uuid().split('-')[0];
        }
    }
}
</script>
