<template>
    <div>
        <draggable
            class="overflow-auto"
            chosenClass="bg-gray-100"
            handle=".handle"
            :list="resourcesArray"
            :group="name"
            @start="drag=true"
            direction="vertical"
            @end="drag=false"
            item-key="unique_id"
        >
            <template #item="{element}">
                <ResourceWrapper
                    :data="element"
                    :unique_id="element.unique_id"
                    @remove="removeResource"
                    @toggle="element.edit = !element.edit"
                ></ResourceWrapper>
            </template>
        </draggable>

        <div v-if="!resourcesArray.length">
            <div class="flex flex-col justify-center items-center gap-4 text-center p-10 border rounded">
                <div class="space-y-2">
                    <h2 class="font-semibold text-lg">{{ zeroFieldsTitle }}</h2>
                    <p class="text-gray-500 text-sm">{{ zeroFieldsMessage }}</p>
                </div>
            </div>
        </div>

        <div class="w-max mt-4">
            <options :is-top="true" :is-right="false">
                <template #toggle>
                    <btn type="secondary" v-on:click.prevent>Add resource</btn>
                </template>
                <template v-slot:items="{ setShow }">
                    <options-item
                        v-for="resource in resourcesList"
                        @click="addResource(resource.type);setShow(false)"
                        :title="resource.name"
                    />
                </template>
            </options>
        </div>

        <input type="hidden" :name="name" :value="resourcesString">
    </div>
</template>

<script setup>
import {ref, defineProps, provide, computed} from 'vue';
import {uniqueId, resourceOptions} from "./helpers";
import draggable from 'vuedraggable';
import ResourceWrapper from './resources/ResourceWrapper.vue';

const props = defineProps({
    name: String,
    resources: Array,
    programType: null,
    fileUploadUrl: String,
    fileDeleteUrl: String,
    zeroFieldsTitle: String,
    zeroFieldsMessage: String,
});

provide('fileUrls', {
    upload: props.fileUploadUrl,
    delete: props.fileDeleteUrl,
});

const drag = ref(false);
const resourcesArray = ref(props.resources);
const resourcesString = computed(() => JSON.stringify(resourcesArray.value));
const resourcesList = resourceOptions(props.programType);

const zeroFieldsTitle = ref(props.zeroFieldsTitle);
const zeroFieldsMessage = ref(props.zeroFieldsMessage);

const addResource = (type) => {
    resourcesArray.value.push({
        type: type,
        unique_id: uniqueId(),
        edit: true,
        title: '',
        description: '',
        main_image: {},
        main_image_retina_resize: false,
    });
}

const removeResource = (uniqueId) => {
    let resourceIndex = resourcesArray.value.findIndex(resource => resource.unique_id === uniqueId);
    if (resourceIndex !== -1) {
        resourcesArray.value.splice(resourceIndex, 1);
    }
}
</script>
