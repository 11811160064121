<template>
    <label class="flex items-center cursor-pointer">
        <input
            :checked="modelValue.includes(value)"
            @input="toggleCheckbox"
            type="checkbox"
            :disabled="disabled"
            :name="name"
            :value="value"
            class="h-4 w-4 cursor-pointer text-teal-600 border-gray-300 rounded-sm focus:ring-teal-100"
        >
        <div class="flex flex-col" v-if="label">
            <span class="block text-sm leading-5 font-medium mx-2">{{ label }}</span>
        </div>
    </label>
</template>

<script>

export default {
    emits: ['update:modelValue'],

    props: {
        label: {},
        disabled: {
            default: false,
        },
        size: {
            default: 'default',
        },
        name: {
            type: String,
            default: '',
        },
        value: {
            required: true,
        },
        modelValue: {
            type: Array,
            default: () => [],
        },
    },

    methods: {
        toggleCheckbox() {
            const newValue = this.modelValue.includes(this.value)
                ? this.modelValue.filter(item => item !== this.value)
                : [...this.modelValue, this.value];
            this.$emit('update:modelValue', newValue);
        },
    },
}
</script>
