<template>
    <file-upload
        :src="data?.url ?? null"
        :path="data?.path ?? null"
        :no_src="no_src"
        :accept="accept"
        :title="title"
        :name="name"
        :upload_url="fileUrls.upload"
        :delete_url="fileUrls.delete"
        :additional_data="{ resource_id: unique_id }"
        @upload:done="uploadDone"
        @delete:done="deleteDone"
    >
        <template v-if="$slots.empty_placeholder" #empty_placeholder>
            <slot name="empty_placeholder"></slot>
        </template>
        <template v-if="$slots.uploaded_placeholder" #uploaded_placeholder>
            <slot name="uploaded_placeholder"></slot>
        </template>
    </file-upload>
</template>

<script setup>
import {defineProps, inject} from 'vue';

const emit = defineEmits(['uploaded', 'deleted']);

const fileUrls = inject('fileUrls');

const props = defineProps({
    unique_id: String,
    data: Object,
    name: String,
    title: String,
    no_src: String,
    accept: String,
});

const uploadDone = (response) => {
    props.data.url = response[props.name].url;
    props.data.path = response[props.name].path;
    emit('uploaded', Object.assign({}, props.data));
}

const deleteDone = (response) => {
    props.data.url = null;
    props.data.path = null;
    emit('deleted', Object.assign({}, props.data));
}
</script>
