<template>
    <div>
        <draggable
            class="space-y-2 overflow-auto"
            v-model="fields"
            handle=".handle"
            group="fields"
            @start="drag=true"
            direction="vertical"
            chosenClass="bg-gray-100"
            @end="drag=false"
            item-key="$index"
        >
            <template #item="{element}">
                <Field :data="element" @remove="removeField" />
            </template>
        </draggable>

        <div v-if="!fields.length" class="rounded border flex justify-center text-sm py-4 px-5">{{this.zeroFieldsMessage}}</div>
    </div>
    <div class="w-max">
        <options :is-top="true" :is-right="false">
            <template #toggle>
                <btn type="secondary" v-on:click.prevent>Add field</btn>
            </template>
            <template v-slot:items="{ setShow }">
                <options-item @click="addField('text');setShow(false)" title="Text"></options-item>
                <options-item @click="addField('input');setShow(false)" title="Input"></options-item>
                <options-item @click="addField('textarea');setShow(false)" title="Textarea"></options-item>
                <options-item @click="addField('checkbox');setShow(false)" title="Checkbox"></options-item>
                <options-item @click="addField('radio');setShow(false)" title="Radio"></options-item>
                <options-item @click="addField('select');setShow(false)" title="Select"></options-item>
                <options-item @click="addField('countries');setShow(false)" title="Countries"></options-item>
                <options-item v-if="isSignupForm && !isLastnameIncluded()" @click="addField('portal_signup_last_name');setShow(false)" title="Last name"></options-item>
                <options-item v-if="isSignupForm && !isLinkKeyIncluded()" @click="addField('portal_signup_link_key');setShow(false)" title="Personalized token"></options-item>
                <options-item v-if="isNPSubscriptionForm && !isNameIncluded()" @click="addField('np_subscription_name');setShow(false)" title="Name"></options-item>
            </template>
        </options>
    </div>

    <input type="hidden" :name="name" :value="fieldsString">
</template>

<script>
import draggable from 'vuedraggable'
import Field from './Field.vue'

export default {
    props: {
        name: {
            type: String,
        },
        value: {
            type: Array,
        },
        zeroFieldsMessage: {
            type: String,
        },
        formType: {
            type: String,
            default: ''
        }
    },

    components: {
        Field,
        draggable
    },

    computed: {
        fieldsString() {
            return JSON.stringify(this.fields)
        },
        isSignupForm() {
            return this.formType === 'signup'
        },
        isNPSubscriptionForm() {
            return this.formType === 'np_subscription'
        }
    },

    data() {
        return {
            fields: [],
            drag: false,
        }
    },

    created() {
        this.fields = this.value || []
    },

    methods: {
        addField(type) {
            this.fields.push({
                type,
                edit: true
            })
        },
        removeField(field) {
            this.fields = this.fields.filter((item) => item !== field)
        },
        isLastnameIncluded() {
            let result = this.fields.filter(field => field.type === 'portal_signup_last_name');
            return result.length;
        },
        isLinkKeyIncluded() {
            let result = this.fields.filter(field => field.type === 'portal_signup_link_key');
            return result.length;
        },
        isNameIncluded() {
            let result = this.fields.filter(field => field.type === 'np_subscription_name');
            return result.length;
        },
    }
}
</script>
