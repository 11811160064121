<template>
    <div v-if="show_banner" class="bg-gradient-to-tr from-white via-gray-50 to-teal-50 border rounded p-4 lg:p-6 xl:p-10">
        <div class="flex justify-between items-center">
            <a :href="createRouteForTemplate('programs.integrations.guides', {program: programId})" class="flex items-center space-x-4 group">
                <div class="h-12 w-12 rounded border flex items-center justify-center flex-shrink-0">
                    <icon name="programs" size="22"></icon>
                </div>
                <div>
                    <h5 class="font-medium mb-0.5 group-hover:underline">Explore integration possibilities</h5>
                    <p class="opacity-60 text-sm">Check the how-to guides for detailed instructions on integrating Partnero with your website or app.</p>
                </div>
            </a>
            <btn type="link" class="rounded-full" size="small" icon-name="close-circle" @click="dismissBanner">
               I've done this
            </btn>
        </div>
    </div>
</template>

<script>
import {createRouteForTemplate} from "../../../../helpers/common";
export default {
    name: 'IntegrationGuideBanner',
    props: {
        programId: {
            type: [Number, String],
            required: true,
        },
        showBanner: {
            type: Boolean,
            default: true,
        },
        dismissUrl: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            show_banner: this.showBanner,
        }
    },
    methods: {
        createRouteForTemplate,
        dismissBanner() {
            this.show_banner = false;
            axios.post(this.dismissUrl, {
                program: this.programId,
            }).then(response => {
                const status = parseInt(response.data.status || -1);
                if (status !== 1) {
                    this.show_banner = true;
                }
            });
        }
    }
}
</script>
