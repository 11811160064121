<template>
    <div>
        <div @click="onShow">
            <slot name="toggle"></slot>
        </div>
        <transition name="fade">
            <div class="flex fixed top-0 left-0 z-50 justify-center items-center w-full h-full" v-if="showModal">
                <div v-on="{ click: closeModalOnOutsideClick ? onCancel : null}"
                     class="flex absolute top-0 z-50 justify-center items-end w-screen h-screen bg-black bg-opacity-50 lg:items-center"></div>
                <div
                    class="flex flex-col relative z-50 mx-8 w-full max-h-screen bg-white rounded-t lg:rounded-b lg:w-156 lg:mx-0"
                    :class="[
                        [sizing[size].style], { 'h-screen': size === 'full' },
                        fieldClass
                    ]">
                    <div class="flex flex-col max-h-screen" :class="{ 'h-screen': size === 'full' }">
                        <div class="flex justify-between items-center gap-2 w-full px-9 pt-9">
                            <div class="flex justify-between">
                                <div class="overflow-hidden">
                                    <h2 class="text-xl font-medium truncate">{{ title }}</h2>
                                    <span v-if="details" class="text-sm text-gray-500 mt-2">{{ details }}</span>
                                </div>
                            </div>
                            <slot name="close-button">
                                <btn @click.native="onCancel" icon-name="close" type="light" size="small"></btn>
                            </slot>
                        </div>
                        <div v-if="$slots.body"
                             :id="'modal-body-' + name ?? Math.random().toString(36).substring(2, 10)"
                             class="flex-1 h-0 text-opacity-50 whitespace-normal overflow-y-auto px-9 py-8"
                        >
                            <slot name="body"></slot>
                        </div>
                        <div class="py-6 px-9 bg-gray-50 rounded-b"
                             v-if="size !== 'full' && size !== 'preview' && size !== 'create' && size !== 'simple' && size !== 'small-simple'">
                            <div class="flex justify-end items-center space-x-3">
                                <slot name="footer-cancel-button"
                                      v-if="showCancelButton">
                                    <btn @click.native="onCancel" type="light">{{ cancel_btn_title }}</btn>
                                </slot>
                                <slot name="footer"></slot>
                            </div>
                        </div>
                        <div v-if="loading"
                             class="flex flex-col absolute h-full w-full justify-center px-20 bg-white bg-opacity-50 z-50">
                            <progress-bar/>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import {onMounted, ref, toRef} from "vue";
import ProgressBar from "./ProgressBar";

export default {
    emits: ['modalClose', 'modalShow'],
    components: {ProgressBar},
    props: {
        title: '',
        details: '',
        name: null,
        size: {
            default: 'default',
        },
        cancel_btn_title: {
            default: 'Cancel'
        },
        show: {
            default: false
        },
        showTopRightCloseButton: {
            default: true
        },
        showCancelButton: {
            default: true
        },
        closeModalOnOutsideClick: {
            default: true
        },
        loading: {
            default: false
        },
        fieldClass: {
            default: '',
        }
    },
    data: function () {
        return {
            sizing: {
                full: {style: 'w-full'},
                preview: {style: 'max-w-2xl'},
                create: {style: 'max-w-4xl'},
                huge: {style: 'max-w-5xl'},
                large: {style: 'max-w-4xl'},
                medium: {style: 'max-w-3xl'},
                default: {style: 'max-w-2xl'},
                simple: {style: 'max-w-2xl'},
                small: {style: 'max-w-lg'},
                'small-simple': {style: 'max-w-lg'},
            }
        }
    },
    setup(props, {emit}) {

        let showModal = ref(false);
        const showProp = toRef(props, 'show');
        const name = props.name;

        const onCancel = () => {
            showModal.value = false;
            emit('modalClose', name);
        }

        const onShow = () => {
            showModal.value = true;
            emit('modalShow', name);
        }

        onMounted(() => {
            showModal.value = showProp.value;
            if(showModal.value === true){
                onShow();
            }
        })

        return {
            showModal,
            onCancel,
            onShow
        }
    },
    mounted() {
        const self = this;
        this.emitter.on('modal-on', function (name) {
            if (self.name !== null && name === self.name) {
                self.onShow();
            }
        });
        this.emitter.on('modal-off', function (name) {
            if (self.name !== null && name === self.name) {
                self.onCancel();
            }
        });
    }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
    transition: opacity .25s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
    opacity: 0;
}
</style>
