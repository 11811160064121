<template>
    <modal title="Sync Shopify customers" size="small" name="customers_sync_modal">
        <template #toggle>
            <btn type="secondary">Sync Shopify customers</btn>
        </template>
        <template #body>
            <div class="space-y-4">
               Would you like to sync customers from "{{ props.storeName }}"?
            </div>
        </template>
        <template #footer>
            <btn :loading="loading" @click="resync" type="primary">Yes, continue</btn>
        </template>
    </modal>
</template>

<script setup>
import {inject, ref} from 'vue';

const props = defineProps({
    program: {
        type: [String, Number],
        required: true
    },
    storeName: {
        type: [String],
        required: false
    }
});

let loading = ref(false);
const emitter = inject('emitter');

const resync = () => {

    if (loading.value === true) {
        return;
    }

    loading.value = true;

    axios.post(route('app_int.integrations.shopify.resync_customers', {program: props.program}))
        .then((response) => {
            emitter.emit('modal-off', 'customers_sync_modal')
        })
        .catch((error) => {
        })
        .finally(() => loading.value = false);
};

</script>
