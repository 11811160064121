<template>
    <div class="space-y-8">
        <div class="max-w-48">
            <img src="/images/logo/partnero-network.svg"/>
        </div>
        <div class="text-gray-500 text-sm font-light leading-relaxed antialiased">
            We’re excited to connect businesses and creators in the Partnero Network, a collaborative place that helps
            companies share audiences, amplify influence, and discover partnerships to grow together.
        </div>
        <div>
            <modal title="Apply for Partnero Network"
                   details="Ready to grow and connect? Fill the form and join the network."
                   size="medium"
                   name="partnero-marketplace-add-submission"
                   :show-cancel-button="false"
            >
                <template #toggle>
                    <btn v-if="!submission.id" size="large" class="rounded-full">Apply now</btn>
                    <btn v-if="submission.id" size="large" class="rounded-full">View submission</btn>
                </template>
                <template #body>

                    <div class="space-y-4">
                        <div class="space-y-2" v-if="step === 1">
                            <div class="grid grid-cols-2 gap-4">

                                <text-field
                                    class="col-span-2"
                                    v-model="businessDataForm.business_name" name="business_name"
                                    label="Name for the listing"
                                    placeholder="E.g. Partnero"
                                >
                                    <template #note>
                                        Your public name or name of the business.
                                    </template>
                                </text-field>

                                <div class="col-span-2">
                                    <file-upload
                                        :src="businessDataForm.branding_img_url"
                                        no_src="https://www.pulsecarshalton.co.uk/wp-content/uploads/2016/08/jk-placeholder-image-768x518.jpg"
                                        accept="image/*"
                                        title="Brand image"
                                        name="partnero_marketplace_submission_branding_img"
                                        :upload_url="brandingUploadRoute"
                                        :delete_url="brandingRemoveRoute"
                                        @upload:done="brandImgUploaded"
                                    ></file-upload>
                                    <small class="text-gray-500 mt-1 block"> Your brand image or logo. </small>
                                </div>

                                <text-field
                                    :prefixes="['https://', 'http://']"
                                    class="col-span-2"
                                    v-model="businessDataForm.website_platform_url" name="website_platform_url"
                                    label="Website"
                                    placeholder="https://"
                                >
                                </text-field>
                                <text-field
                                    class="col-span-2"
                                    v-model="businessDataForm.contact_name" name="contact_name"
                                    label="Your name"
                                    placeholder="Name"
                                >
                                </text-field>
                                <text-field
                                    class="col-span-2"
                                    v-model="businessDataForm.contact_email" name="contact_email"
                                    label="Contact email address"
                                    placeholder="your@email.com"
                                >
                                </text-field>
                                <text-field
                                    class="col-span-2"
                                    v-model="businessDataForm.industry_niche" name="industry_niche"
                                    label="Industry or Niche"
                                    placeholder="E.g. YouTube videos"
                                >
                                </text-field>
                            </div>
                        </div>

                        <div v-if="step === 2">

                            <div class="space-y-4">

                                <div class="">
                                    <label class="block text-sm mb-1.5">Description of your services</label>
                                    <textarea
                                        rows="5"
                                        name="general_description_of_services"
                                        v-model="businessOffersForm.general_description_of_services"
                                        class="block w-full text-sm rounded border-gray-300 focus:ring-0 focus:border-gray-400 mb-1 scroll-h"></textarea>
                                </div>

                                <h3 class="text-sm font-semibold">
                                    What services do you offer for brand promotion and publicity?
                                </h3>

                                <toggle title="Newsletter Sponsorship" v-model="businessOffersForm.subscribers_list">
                                    <template #description>
                                        E.g. curated newsletter sent to an engaged audience.
                                    </template>
                                </toggle>
                                <div class="grid grid-cols-2 gap-4 border-b pb-4"
                                     v-if="businessOffersForm.subscribers_list">
                                    <text-field v-model="businessOffersForm.subscribers_list_subscriber_count"
                                                name="subscribers_list_subscriber_count"
                                                label="Subscriber count"
                                                size="small"

                                    ></text-field>
                                    <text-field v-model="businessOffersForm.subscribers_list_engagement_rate"
                                                name="subscribers_list_engagement_rate"
                                                label="Engagement rate"
                                                size="small"
                                    ></text-field>
                                </div>
                                <toggle title="Blog Articles & Sponsored Content"
                                        v-model="businessOffersForm.blog_articles__publicity">
                                    <template #description>
                                        E.g. sponsored content, product reviews, etc.
                                    </template>
                                </toggle>
                                <div class="grid grid-cols-3 gap-4 border-b pb-4"
                                     v-if="businessOffersForm.blog_articles__publicity">
                                    <text-field v-model="businessOffersForm.blog_articles__publicity_url"
                                                name="blog_articles__publicity_url"
                                                label="URL"
                                                size="small"
                                    ></text-field>
                                    <text-field
                                        v-model="businessOffersForm.blog_articles__publicity_monthly_traffic"
                                        name="blog_articles__publicity_monthly_traffic"
                                        label="Monthly traffic"
                                        size="small"
                                    ></text-field>
                                    <text-field
                                        v-model="businessOffersForm.blog_articles__publicity_average_time_spent_on_page"
                                        name="blog_articles__publicity_average_time_spent_on_page"
                                        label="Avg. time spent on page"
                                        size="small"
                                    ></text-field>
                                </div>
                                <toggle title="Social Media Promotions"
                                        v-model="businessOffersForm.social_media">
                                    <template #description>
                                        E.g. posts, stories, reels
                                    </template>
                                </toggle>
                                <div class="grid grid-cols-4 gap-4 border-b pb-4"
                                     v-if="businessOffersForm.social_media">
                                    <text-field v-model="businessOffersForm.social_media_link"
                                                name="social_media_link"
                                                label="Link or links"
                                                size="small"
                                                class="col-span-2"
                                    ></text-field>
                                    <text-field v-model="businessOffersForm.social_media_follower_count"
                                                name="social_media_follower_count"
                                                label="Follower count"
                                                size="small"
                                    ></text-field>
                                    <text-field v-model="businessOffersForm.social_media_engagement_rate"
                                                name="social_media_engagement_rate"
                                                label="Engagement rate"
                                                size="small"
                                    ></text-field>
                                </div>
                                <toggle title="Other" v-model="businessOffersForm.other">
                                    <template #description>
                                        Any other publicity services or unique offerings not listed above.
                                    </template>
                                </toggle>
                                <div v-if="businessOffersForm.other">
                                    <textarea v-model="businessOffersForm.other_description" id="trusted-domains"
                                              rows="5"
                                              class="block w-full text-sm rounded border-gray-300 focus:ring-0 focus:border-gray-400 mb-1 scroll-h"></textarea>
                                </div>

                            </div>

                        </div>
                    </div>

                </template>
                <template #footer>

                    <modal v-if="submission.id" title="Remove submission" size="small"
                           name="partnero-marketplace-remove-submission">
                        <template #toggle>
                            <btn type="light">Remove submission</btn>
                        </template>
                        <template #body>

                            <div class="space-y-6">
                                <div class="text-sm text-gray-500">
                                    Do you really want to remove your submission?
                                </div>
                            </div>

                        </template>
                        <template #footer>
                            <btn @click="removeSubmission" :loading="loading.removing">Yes, remove</btn>
                        </template>
                    </modal>

                    <btn @click="showStep(2)" v-if="step === 1">Next</btn>
                    <btn type="secondary" @click="showStep(1)" v-if="step === 2">Back</btn>
                    <btn v-if="step === 2 && !submission.id" @click="saveSubmission" :loading="loading.saving">Submit
                    </btn>
                    <btn v-if="step === 2 && submission.id" @click="saveSubmission" :loading="loading.saving">Update
                    </btn>
                </template>
            </modal>
        </div>
    </div>
</template>
<script setup>
import {ref, defineProps, getCurrentInstance} from 'vue';
import axios from 'axios';
import Modal from "../../../../elements/Modal.vue";

const {proxy} = getCurrentInstance()
const props = defineProps({
    submission: {
        type: Object,
        default: {}
    }
});
const step = ref(1);
const error = ref('');
const submission = ref(props.submission);

const loading = ref({
    saving: false,
    removing: false,
});

const setBusinessData = () => {
    return {
        branding_img: submission.value.business_data?.branding_img ?? null,
        branding_img_url: submission.value.business_data?.branding_img_url ?? null,
        business_name: submission.value.business_data?.business_name ?? null,
        website_platform_url: submission.value.business_data?.website_platform_url ?? null,
        contact_name: submission.value.business_data?.contact_name ?? null,
        contact_email: submission.value.business_data?.contact_email ?? null,
        industry_niche: submission.value.business_data?.industry_niche ?? null,
    };
}

const setBusinessOffers = () => {
    return {
        subscribers_list: submission.value.business_offers?.subscribers_list ?? false,
        subscribers_list_subscriber_count: submission.value.business_offers?.subscribers_list_subscriber_count ?? null,
        subscribers_list_engagement_rate: submission.value.business_offers?.subscribers_list_engagement_rate ?? null,
        blog_articles__publicity: submission.value.business_offers?.blog_articles__publicity ?? false,
        blog_articles__publicity_url: submission.value.business_offers?.blog_articles__publicity_url ?? null,
        blog_articles__publicity_monthly_traffic: submission.value.business_offers?.blog_articles__publicity_monthly_traffic ?? null,
        blog_articles__publicity_average_time_spent_on_page: submission.value.business_offers?.blog_articles__publicity_average_time_spent_on_page ?? null,
        social_media: submission.value.business_offers?.social_media ?? false,
        social_media_link: submission.value.business_offers?.social_media_link ?? null,
        social_media_follower_count: submission.value.business_offers?.social_media_follower_count ?? null,
        social_media_engagement_rate: submission.value.business_offers?.social_media_engagement_rate ?? null,
        other: submission.value.business_offers?.other ?? false,
        other_description: submission.value.business_offers?.other_description ?? null,
        general_description_of_services: submission.value.business_offers?.general_description_of_services ?? null,
    };
}

const businessDataForm = ref(setBusinessData());
const businessOffersForm = ref(setBusinessOffers());

const brandingUploadRoute = route('app_int.partnero-marketplace.submission.branding_image_upload');
const brandingRemoveRoute = route('app_int.partnero-marketplace.submission.branding_image_delete');

const showStep = (stepValue) => {
    step.value = stepValue;
}

const brandImgUploaded = (upload) => {
    businessDataForm.value.branding_img = upload.partnero_marketplace_submission_branding_img.path;
};

const saveSubmission = () => {
    if (loading.value.saving) {
        return;
    }

    error.value = '';
    loading.value.saving = true;

    axios
        .post(route('app_int.partnero-marketplace.submission.add'), {
            data: businessDataForm.value,
            offers: businessOffersForm.value,
            form_id: 'for_business_v1',
            submission_id: submission.value.id ?? null
        })
        .then(response => {
            const status = parseInt(response.data.status || -1);
            if (status === 1) {
                proxy.emitter.emit('modal-off', 'partnero-marketplace-add-submission');
                step.value = 1;
            }

            if (response.data.submission ?? null) {
                submission.value = response.data.submission;
                businessDataForm.value = setBusinessData();
                businessOffersForm.value = setBusinessOffers();
            }
        })
        .catch(err => {
            const error = err.response?.data?.message ?? 'Failed to save submission';
        })
        .finally(() => {
            loading.value.saving = false;
        });
}

const removeSubmission = () => {
    if (loading.value.removing) {
        return;
    }

    error.value = '';
    loading.value.removing = true;

    axios
        .post(route('app_int.partnero-marketplace.submission.remove'), {
            submission_id: submission.value.id ?? null
        })
        .then(response => {
            const status = parseInt(response.data.status || -1);
            if (status === 1) {
                proxy.emitter.emit('modal-off', 'partnero-marketplace-add-submission');
                proxy.emitter.emit('modal-off', 'partnero-marketplace-remove-submission');
                step.value = 1;
                submission.value = {};

                businessDataForm.value = setBusinessData();
                businessOffersForm.value = setBusinessOffers();
            }

            if (response.data.submission ?? null) {
                submission.value = response.data.submission;
            }
        })
        .catch(err => {
            const error = err.response?.data?.message ?? 'Failed to remove submission';
        })
        .finally(() => {
            loading.value.removing = false;
        });
}
</script>

