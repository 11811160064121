<template>
    <div>
        <tooltip :content="tooltip">
            <toggle
                :name="toggleInputName"
                title="Enable in-site widget"
                v-model="widget"
                @update:model-value="handleToggleUpdate"
                :disabled="disabled"
            >
                <template #description>
                    When enabled, portal can be accessed via in-site widget on your site or app.
                </template>
            </toggle>
        </tooltip>

        <input type="hidden" :name="whitelistInputName" v-model="whitelistDomainsJson" :disabled="disabled">

        <card v-if="widget" class="flex-1 ml-16 p-4 mt-4" :class="disabled ? 'cursor-not-allowed opacity-50' : ''" #body inset="none">

            <div class="space-y-1">
                <div class="text-sm font-medium text-gray-900">Configure the widget to display on specific domains</div>
                <div class="text-sm text-gray-500">Provide a domain or subdomain where you want the in-site widget to appear.</div>
            </div>

            <div v-if="whitelist.length > 0" class="mt-4">
                <ul class="text-sm space-y-2">
                    <template v-for="domain in whitelist">
                        <li v-if="domain">
                            <span class="flex gap-1 items-center font-medium text-sm text-teal-600 hover:underline">
                                <icon name="external-link" size="14"></icon>
                                <span class="text-sm">{{ domain }}</span>
                            </span>
                        </li>
                    </template>
                </ul>
            </div>

            <modal v-if="!disabled" title="Whitelist domains" size="small" name="whitelist_domain_add_modal">
                <template #toggle>
                    <btn type="secondary" size="small" class="mt-4">Edit list</btn>
                </template>
                <template #body>
                    <div class="space-y-6">
                        <div class="border rounded p-4">
                            <div class="divide-y">
                                <div v-for="(domain, index) in whitelist">
                                    <div class="flex items-end align-center gap-4 py-2">
                                        <text-field
                                            v-model="whitelist[index]"
                                            placeholder="e.g. referral.partnero.com"
                                            class="w-full"
                                        ></text-field>
                                        <btn @click="removeDomain(index)" type="secondary" icon-name="delete"></btn>
                                    </div>
                                </div>
                            </div>

                            <btn @click="addDomain" type="secondary" size="small" icon-name="add-circle">
                                Add domain
                            </btn>

                        </div>
                    </div>
                </template>
                <template #footer>
                    <btn @click="saveDomains">Confirm</btn>
                </template>
            </modal>
        </card>
    </div>
</template>
<script setup>
import {ref, defineProps, computed, inject} from 'vue';
import Card from "../../../../elements/cards/Card";

// Define props
const props = defineProps({
    toggleInputName: String,
    whitelistInputName: String,
    widgetEnabled: Boolean,
    widgetWhitelistDomains: Array,
    defaultWhitelistDomains: Array,
    disabled: {
        type: Boolean,
        default: false
    },
    tooltip: {
        type: String,
        default: null
    }
});

const widget = ref(props.widgetEnabled || false);
const whitelist = ref(props.widgetWhitelistDomains || props.defaultWhitelistDomains || []);
const whitelistDomainsJson = ref(JSON.stringify(whitelist.value));
const emitter = inject('emitter')

const addDomain = () => {
    whitelist.value.push('')
}

const removeDomain = (index) => {
    whitelist.value.splice(index, 1)
}

const handleToggleUpdate = (value) => {
    if (value) {
        if (whitelist.value.length === 0) {
            whitelist.value = props.defaultWhitelistDomains || [];
        }
    }
    saveDomains()
}

const saveDomains = () => {
    whitelistDomainsJson.value = JSON.stringify(whitelist.value.filter((el) => el !== ''));
    emitter.emit('modal-off', 'whitelist_domain_add_modal')
}
</script>
