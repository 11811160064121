<template>
    <div>
        <div v-if="dynamicPeriodCommissions.length > 0" class="space-y-4">
            <div v-for="(dynamicPeriodCommission, index) in dynamicPeriodCommissions" class="items-center p-5 border rounded">
                <div class="flex gap-4 rounded">
                    <div class="flex relative rounded-md mr-1">
                        <div class="flex items-center text-sm text-gray-500 ml-4">The period is</div>
                    </div>
                    <select-field v-model="dynamicPeriodCommission.condition_type" @input="commissionUpdated">
                        <option value="">select</option>
                        <option v-for="conditionType in props.dynamicPeriodCommissionConditionTypes" :value="conditionType.key">
                            {{ conditionType.label }}
                        </option>
                    </select-field>
                    <div class="flex gap-1">
                        <text-field
                            type="text"
                            v-model="dynamicPeriodCommission.period_times"
                            @input="commissionUpdated"
                            class="w-1/3"
                            placeholder="e.g. 1"
                        >
                        </text-field>
                        <select-field v-model="dynamicPeriodCommission.period_type" @input="commissionUpdated">
                            <option value="">select</option>
                            <option value="months">Months</option>
                            <option value="years">Years</option>
                        </select-field>
                    </div>
                </div>
                <card #body inset="none" class="my-5 p-5">
                    <div class="grid grid-cols-4 gap-4 mb-4">
                        <div>
                            <div class="flex relative rounded-md">
                                <span
                                    v-if="props.typeIsPercent"
                                    class="inline-flex items-center px-5 text-gray-500 bg-gray-50 rounded-l border border-r-0 border-gray-300 sm:text-sm">
                                    %
                                </span>
                                <input
                                    type="text"
                                    placeholder="e.g. 10"
                                    v-model="dynamicPeriodCommission.commission_value"
                                    class="block w-full rounded-r border-gray-300 transition-shadow sm:text-sm focus:ring-0 focus:border-gray-400"
                                    :class="{'rounded-l': !props.typeIsPercent}"
                                />
                            </div>
                        </div>
                        <div class="text-sm text-gray-500 flex items-center">
                            is for everyone.
                        </div>
                    </div>
                    <div v-if="dynamicPeriodCommission.dynamic_commissions?.length > 0" class="my-2 space-y-2">
                        <DynamicRow
                            v-for="(dynamicCommission, itemIndex) in dynamicPeriodCommission.dynamic_commissions"
                            :type-is-percent="props.typeIsPercent"
                            v-model:commission-value="dynamicCommission.commission_value"
                            v-model:condition-element="dynamicCommission.condition_element"
                            v-model:condition-type="dynamicCommission.condition_type"
                            v-model:condition-value="dynamicCommission.condition_value"
                            :condition-elements="dynamicCommissionConditionElements"
                            :condition-types="dynamicCommissionConditionTypes"
                            @remove="removeDynamicPeriodCommissionItem(index, itemIndex)"
                        ></DynamicRow>
                    </div>
                    <div v-else class="text-sm text-gray-500 bg-gray-100 rounded py-4 px-4">
                        Add a dynamic commission structure based on partner achievements.
                    </div>
                </card>
                <div class="py-3 self-stretch overflow-hidden">
                    <hr class="border border-b-0">
                    <span class="text-xs block bg-white text-black px-2 py-1 -mt-3 float-left ml-3 uppercase">Actions</span>
                </div>
                <div class="flex gap-2">
                    <btn v-if="canAddDynamicPeriodCommissionItem(index)" type="secondary" size="small" icon-name="add-circle" @click="addDynamicPeriodCommissionItem(index)">
                        Add
                    </btn>
                    <btn type="secondary" size="small" icon-name="trash" @click="removeDynamicPeriodCommission(index)">
                        Remove period
                    </btn>
                </div>
            </div>
            <div v-if="canAddDynamicPeriodCommission" class="items-center">
                <btn type="secondary" size="small" icon-name="calendar" @click="addDynamicPeriodCommission(null)">
                    Add period
                </btn>
            </div>
        </div>
        <div v-else class="space-y-4">
            <div v-if="dynamicCommissions.length === 0" class="text-sm text-gray-500 bg-gray-100 rounded py-4 px-4">
                Add a dynamic commission structure based on partner achievements.
            </div>
            <template v-for="(dynamicCommission, index) in dynamicCommissions" :key="index">
                <DynamicRow
                    :type-is-percent="props.typeIsPercent"
                    v-model:commission-value="dynamicCommission.commission_value"
                    v-model:condition-element="dynamicCommission.condition_element"
                    v-model:condition-type="dynamicCommission.condition_type"
                    v-model:condition-value="dynamicCommission.condition_value"
                    :condition-elements="dynamicCommissionConditionElements"
                    :condition-types="dynamicCommissionConditionTypes"
                    @remove="removeDynamicCommission(index)"
                ></DynamicRow>
            </template>

            <div v-if="dynamicCommissions.length > 0 && canAddDynamicCommission" class="py-3 self-stretch">
                <hr class="border border-b-0">
                <span class="text-xs block bg-white text-black px-2 py-1 -mt-3 float-left ml-3 uppercase">Actions</span>
            </div>

            <div v-if="canAddDynamicCommission" class="flex gap-2">
                <btn type="secondary" size="small" icon-name="add-circle" @click="addDynamicCommission">
                    Add
                </btn>
                <btn type="secondary" size="small" icon-name="calendar" @click="convertToPeriod">
                    Create a period
                </btn>
            </div>
        </div>
    </div>
</template>

<script setup>
import DynamicRow from './DynamicRow';
import {computed, ref} from "vue";
import Card from "../../../../../elements/cards/Card";

const props = defineProps({
    typeIsPercent: {
        type: Boolean,
        required: true
    },
    defaultCommission: {
        type: [Number, String],
    },
    dynamicCommissions: {
        type: Array,
        default(rawProps) {
            return [];
        }
    },
    dynamicPeriodCommissions: {
        type: Array,
        default(rawProps) {
            return [];
        }
    },
    dynamicCommissionConditionElements: {
        type: Object,
        default: () => {
        }
    },
    dynamicCommissionConditionTypes: {
        type: Object,
        default: () => {
        }
    },
    dynamicPeriodCommissionConditionTypes: {
        type: Object,
        default: () => {
        }
    },
});
const emit = defineEmits(['update:dynamic-commissions', 'update:dynamic-period-commissions'])

const dynamicCommissions = ref(props.dynamicCommissions);
const dynamicPeriodCommissions = ref(props.dynamicPeriodCommissions);

const canAddDynamicCommission = computed(() => dynamicCommissions.value.length < 5);
const canAddDynamicPeriodCommission = computed(() => dynamicPeriodCommissions.value.length < 5);
const canAddDynamicPeriodCommissionItem = (index) => !Array.isArray(dynamicPeriodCommissions.value[index]?.dynamic_commissions)
    || dynamicPeriodCommissions.value[index]?.dynamic_commissions?.length < 5;

const dynamicCommissionObj = {
    commission_value: '',
    condition_element: '',
    condition_type: '',
    condition_value: '',
}

const addDynamicCommission = () => {
    if (canAddDynamicCommission) {
        dynamicCommissions.value.push({...dynamicCommissionObj});
        commissionUpdated();
    }
};

const addDynamicPeriodCommission = (dynamicCommissionToAdd) => {
    if (canAddDynamicPeriodCommission) {
        dynamicPeriodCommissions.value.push({
            commission_value: props.defaultCommission || '',
            condition_type: '',
            period_times: '',
            period_type: '',
            dynamic_commissions: dynamicCommissionToAdd || []
        });
        commissionUpdated();
    }
};

const addDynamicPeriodCommissionItem = (index) => {
    if (dynamicPeriodCommissions.value[index] && canAddDynamicPeriodCommissionItem(index)) {
        if (!dynamicPeriodCommissions.value[index].dynamic_commissions) {
            dynamicPeriodCommissions.value[index].dynamic_commissions = [];
        }
        dynamicPeriodCommissions.value[index].dynamic_commissions.push({...dynamicCommissionObj});
        commissionUpdated()
    }
}

const convertToPeriod = () => {
    if (dynamicPeriodCommissions.value.length === 0) {
        let dynamicCommissionToAdd = JSON.parse(JSON.stringify(dynamicCommissions.value))
        dynamicCommissions.value.splice(0, dynamicCommissions.value.length)
        addDynamicPeriodCommission(dynamicCommissionToAdd)
    }
};

const removeDynamicCommission = (index) => {
    dynamicCommissions.value.splice(index, 1);
    commissionUpdated();
};

const removeDynamicPeriodCommission = (index) => {
    if (dynamicPeriodCommissions.value.length === 1) {
        dynamicCommissions.value = JSON.parse(JSON.stringify(dynamicPeriodCommissions.value[0].dynamic_commissions || []))
    }
    dynamicPeriodCommissions.value.splice(index, 1);
    commissionUpdated()
};

const removeDynamicPeriodCommissionItem = (index, itemIndex) => {
    dynamicPeriodCommissions.value[index]?.dynamic_commissions?.splice(itemIndex, 1);
    commissionUpdated()
};

const commissionUpdated = () => {
    emit('update:dynamic-commissions', dynamicCommissions.value);
    emit('update:dynamic-period-commissions', dynamicPeriodCommissions.value);
}
</script>
