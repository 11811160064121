<template>
    <div>
        <div class="grid space-y-2" v-if="emailsListStore.templates?.[templates_group]?.length > 0">
            <h4 class="text-md font-medium mb-1">
                {{ templates_group.charAt(0).toUpperCase() + templates_group.slice(1) }}
            </h4>
            <email-list-items :templates-group="templates_group"/>
        </div>
        <notification
            :show="emailsListStore.notification.showNotification"
            :title="emailsListStore.notification.notificationTitle"
            :type="emailsListStore.notification.notificationType"
            class="mb-20"
            @notificationClosed="emailsListStore.notification.showNotification = false"
        >
            <template #message>
                {{ emailsListStore.notification.notificationMessage }}
            </template>
        </notification>
    </div>
</template>
<script setup>
import {onMounted} from "vue";
import {useRevolvappEmailsListStore} from "../../../stores/app/emails/revolvapp/RevolvAppEmaisListStore";
import EmailListItems from "./emails-list-components/EmailListItems.vue";

    let emailsListStore = useRevolvappEmailsListStore()

    const props = defineProps({
        test_email_address: {
            type: String
        },
        email_templates: {
            type: Object
        },
        templates_group: {
            type: String,
            required: true
        },
        make_email_url: {
            type: String
        },
        send_test_email_url: {
            type: String
        },
        update_emails_url: {
            type: String
        },
        publish_draft_email_url: {
            type: String
        },
    })

    const prepNotPrepared = (email_template) => {
        if (!email_template.mail) {
            makeTemplateEmail(email_template);
        }
    }

    const makeTemplateEmail = (email_template) => {
        axios
            .post(props.make_email_url, {slug: email_template.slug})
            .then(response => {
                const status = parseInt(response.data.status || 0);
                if (status === 1 && response.data.email.mail) {
                    email_template.mail = response.data.email.mail;
                    email_template.urls = response.data.email.urls;
                    setNewProps(email_template);
                }
            })
            .catch(error => {
            });
    }

    const setNewProps = (email_template) => {
        try {
            email_template.can_edit = typeof email_template.urls?.edit === 'string';
        } catch (e) {
            email_template.can_edit = false;
        }
        try {
            email_template.can_preview = typeof email_template.urls?.preview === 'string';
        } catch (e) {
            email_template.can_preview = false;
        }
    }

    onMounted(() => {
        emailsListStore.templates[props.templates_group] = props.email_templates
        emailsListStore.sendTestEmailUrl = props.send_test_email_url
        emailsListStore.updateEmailsUrl = props.update_emails_url
        emailsListStore.publishDraftEmailUrl = props.publish_draft_email_url

        for (let template in emailsListStore.templates[props.templates_group]) {
            setNewProps(emailsListStore.templates[props.templates_group][template]);
            prepNotPrepared(emailsListStore.templates[props.templates_group][template]);
        }
    })
</script>

