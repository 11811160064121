<template>
    <div>

        <div class="p-4 bg-gray-50 bg-opacity-75 border-t space-y-4">
            <div class="grid lg:grid-cols-2 gap-8 gap-y-6">
                <div>
                    <FileUpload
                        :data="data.files.asset_image"
                        :options="options"
                        :unique_id="unique_id"
                        accept="image/*"
                        title="Image"
                        name="asset_image"
                        no_src="https://via.placeholder.com/300x100/E5E7EB/9CA3AF?text=img"
                        :allow_external_url="!data.files.asset_image.url && !data.files.asset_image.path"
                    ></FileUpload>
                </div>
                <div>
                    <FileUpload
                        :data="data.files.asset_content"
                        :options="options"
                        :unique_id="unique_id"
                        accept=".zip, .pdf"
                        title="Zip or PDF"
                        name="asset_content"
                        :allow_external_url="!data.files.asset_content.url && !data.files.asset_content.path"
                    >
                        <template v-slot:empty_placeholder>
                            <div class="">
                                <span class="text-sm text-gray-500 hidden">No file</span>
                                <icon name="file-missing" size="24" />
                            </div>
                        </template>

                        <template v-slot:uploaded_placeholder>
                            <div class="">
                                <span class="text-sm text-gray-500 hidden">File present</span>
                                <icon name="file" size="24" />
                            </div>
                        </template>
                    </FileUpload>
                </div>
                <div class="flex justify-between items-center col-span-2">
                    <toggle
                        @update:model-value="data.files.asset_image.downloadable = $event"
                        :model-value="data?.files?.asset_image?.downloadable ?? false"
                    >
                    </toggle>
                    <span class="flex flex-col flex-grow">
                        <span class="text-sm font-medium">Make image downloadable</span>
                         <span class="text-sm text-gray-500">Partner will be able to download the uploaded image.</span>
                    </span>
                </div>
                <div class="col-span-2">
                    <label class="block mb-1.5 text-sm"> Title </label>
                    <input
                        type="text"
                        v-model="data.title"
                        class="block w-full text-sm rounded border-gray-300 focus:border-gray-400 focus:ring-0"/>
                </div>
                <div class="col-span-2">
                    <label class="block mb-1.5 text-sm"> Description </label>
                    <textarea
                        v-model="data.description"
                        type="text"
                        class="block w-full text-sm rounded border-gray-300 focus:border-gray-400 focus:ring-0"></textarea>

                </div>
            </div>
        </div>

    </div>
</template>
<script>
import FileUpload from './includes/FileUpload'
import Icon from "../../../../elements/Icon.vue";

export default {
    props: ['unique_id', 'data', 'options'],
    components: {
        Icon,
        FileUpload
    },
    created() {
        this.data.files = this.data?.files ?? {};
        this.data.files.asset_image = this.data.files.asset_image ?? {};
        this.data.files.asset_content = this.data.files.asset_content ?? {};
    }
}
</script>

<style lang="scss"></style>
