<script setup>

import DoubleNameFileUpload from "./components/DoubleNameFileUpload.vue";
import {useEmailSettingsStore} from "../../../../stores/app/emails/settings/EmailSettingsStore";
import {onMounted} from "vue";
import Spinner from "../../../../elements/Spinner.vue";
import FormSection from "../../../../elements/forms/FormSection";

const props = defineProps({
    program: {
        type: [Number, Array, Object]
    },
    srcFileLogo: {
        type: String,
        default: null
    },
    srcFileSymbol: {
        type: String,
        default: null
    },
    portalLayoutBrandPrimaryColor: {
        type: String,
        default: null
    }
})

let emailSettingsStore = useEmailSettingsStore()

const uploadUrl = route('app_int.program_settings_file.upload', props.program)
const deleteUrl = route('app_int.program_settings_file.delete', props.program)

onMounted(() => {
    emailSettingsStore.program = props.program
    emailSettingsStore.init()
})

const updateStoreLogoSettings = (data) => {
    emailSettingsStore.settings.emails_logo_file = data['emails_logo_file']?.url
    emailSettingsStore.updateSettings()
}
const setStoreLogoSettingsToNull = () => {
    emailSettingsStore.settings.emails_logo_file = null
    emailSettingsStore.updateSettings()
}

const updateStoreValueAndEmailTemplates = (data, settingsKey) => {

    if (data?.pickedValue === 'custom') {
        // prevent unnecessary update and errors on an initial load if the custom value is already set
        if (data?.customValue !== props.portalLayoutBrandPrimaryColor) {

            const hexValue = getHexValue(data.customValue)
            emailSettingsStore.settings[settingsKey] = hexValue

            if (hexValue.length > 6 && hexValue.includes('#')) {
                emailSettingsStore.updateSettings()
            }
        }

    } else {
        emailSettingsStore.settings[settingsKey] = getHexValue(data.pickedValue)
        emailSettingsStore.updateSettings()
    }
}

const getHexValue = (color) => {
    switch (color) {
        case 'indigo-700':
            return '#4338ca'
        case 'blue-700':
            return '#1d4ed8'
        case 'cyan-700':
            return '#0e7490'
        case 'teal-600':
            return '#0d9488'
        case 'black':
            return '#000000'
        default:
            return color.includes('#') ? color : ('bg-' + color)
    }
}

</script>

<template>

    <form-layout>
        <form-section title="Partner portal branding" description="Create the main branding for your Affiliate portal.">

            <div v-if="emailSettingsStore.getUpdatingSettingsStatus" class="flex items-center">
                <spinner class="mr-3"/>
                <h3 class="text-sm font-medium" >Updating brand settings...</h3>
            </div>

            <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
                <div>
                    <double-name-file-upload
                        :src="srcFileLogo"
                        no_src="https://www.pulsecarshalton.co.uk/wp-content/uploads/2016/08/jk-placeholder-image-768x518.jpg"
                        accept="image/*"
                        title="Logo"
                        name="portal_layout_file_logo"
                        name2="emails_logo_file"
                        @upload:done="updateStoreLogoSettings"
                        @delete:done="setStoreLogoSettingsToNull"
                        :upload_url="uploadUrl"
                        :delete_url="deleteUrl"
                    ></double-name-file-upload>
                    <small class="text-gray-500 mt-1 block">Logo is used in affiliate dashboard.</small>
                    <!-- Needed to instantiate revolvapp and update templates -->
                    <div id="revolvApp" class="hidden"/>
                </div>
                <div>
                    <file-upload
                        :src="srcFileSymbol"
                        no_src="https://via.placeholder.com/300x100/E5E7EB/9CA3AF?text=img"
                        accept="image/*"
                        title="Symbol"
                        name="portal_layout_file_symbol"
                        :upload_url="uploadUrl"
                        :delete_url="deleteUrl"
                    ></file-upload>
                    <small class="text-gray-500 mt-1 block">Logo is used in program description page.
                        Recommended size at least 400x400px.</small>
                </div>
                <div>
                    <radio-group-inline-color
                        label="Brand color"
                        name="portal_layout_brand_primary_color"
                        @picked="updateStoreValueAndEmailTemplates($event, 'email_brand_color')"
                        :model-value="portalLayoutBrandPrimaryColor"
                        :options="[
                            { value: 'indigo-700', label: 'bg-indigo-700', label_is_class: true},
                            { value: 'blue-700', label: 'bg-blue-700', label_is_class: true},
                            { value: 'cyan-700', label: 'bg-cyan-700', label_is_class: true},
                            { value: 'teal-600', label: 'bg-teal-600', label_is_class: true},
                            { value: 'black', label: 'bg-black', label_is_class: true},
                            { value: 'custom', label: 'Custom', custom: { name: 'portal_layout_brand_primary_color', placeholder: '', type: 'input' }},
                    ]">

                    </radio-group-inline-color>
                    <small class="text-gray-500 mt-1 block">The main color of your brand. For custom color,
                        please use HEX, e.g. #2DD4BF</small>
                </div>
            </div>
        </form-section>
    </form-layout>


    <div class="flex flex-col items-center gap-6 hidden">



        <icon name="brush-line" size="36"></icon>

        <div class="flex flex-col items-center space-y-1">
            <span class="font-semibold text-2xl">Would you like to brand your Affiliate portal?</span>
            <span
                class="text-gray-500 text-md">Create the main branding for your Affiliate portal.</span>
        </div>

    </div>
</template>

<style scoped>

</style>
