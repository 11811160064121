<template>

    <div v-if="errors.length > 0">
        <div class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative text-sm mb-6" role="alert">
            <ul>
                <li v-for="error in errors">{{ error }}</li>
            </ul>
        </div>
    </div>

    <div class="bg-white border rounded relative">
        <div v-if="step === 1">
            <div class="px-10 py-14">

                <form-layout>
                    <form-section title="Program" description="Set up the main details of your affiliate program.">
                        <text-field
                            label="Program name"
                            field-id="name"
                            name="name"
                            type="text"
                            autofocus="true"
                            v-model="data.program_name"
                            required="true">
                            <template #note>
                                This name will be visible to your partners when they sign up.
                            </template>
                        </text-field>

                        <text-field
                            label="Website URL"
                            field-id="website"
                            name="landing_page_url"
                            type="text"
                            placeholder="https://"
                            :prefixes="['https://', 'http://']"
                            v-model="data.landing_page_url"
                            required="true">
                            <template #note>
                                The page that your partners’ affiliate links will lead to.
                            </template>
                        </text-field>

                        <div>
                            <label class="block mb-1.5 text-sm">
                                Partner portal URL
                            </label>
                            <div class="flex rounded">
                        <span
                            class="inline-flex items-center px-3 text-gray-500 bg-gray-50 rounded-l border border-r-0 border-gray-300 sm:text-sm">https://</span>
                                <div class="flex flex-grow items-stretch focus-within:z-10">
                                    <input type="text" name="subdomain" id="subdomain" v-model="data.subdomain"
                                           class="block w-full border-gray-300 transition-shadow text-sm focus:ring-0 focus:border-gray-400">
                                </div>
                                <span
                                    class=" inline-flex items-center py-2 px-3 text-gray-500 bg-gray-50 rounded-r border border-l-0 border-gray-300 sm:text-sm">.partneroapp.com</span>
                            </div>
                            <small class="text-gray-500 mt-1 block">The partner portal log-in page. You can use our
                                default link, or connect your own domain.</small>
                        </div>

                    </form-section>
                </form-layout>
            </div>
            <div class="bg-gray-50 px-10 py-4 text-right rounded-b sticky bottom-0 z-40">
                <btn button-type="button" type="primary" @click="validateStepOne" :loading="loading.validatingStepOne">
                    Next
                </btn>
            </div>
        </div>
        <div v-if="step === 2">
            <div class="px-10 py-14">
                <form-layout>
                    <form-section title="Commission structure"
                                  description="Specify how you plan to reward your partners.">
                        <radio-group
                            name="commission_options"
                            id="predefined_commission_options"
                            :model-value="predefinedCommissionOption"
                            :options="[
                                { value: '1', label: optionsConfig['1'].label, description: optionsConfig['1'].description},
                                { value: '2', label: optionsConfig['2'].label, description: optionsConfig['2'].description},
                                { value: '3', label: optionsConfig['3'].label, description: optionsConfig['3'].description},
                                { value: '4', label: optionsConfig['4'].label, description: optionsConfig['4'].description},
                                { value: '5', label: optionsConfig['5'].label, description: optionsConfig['5'].description},
                            ]"
                            @update:model-value="predefinedCommissionOptionChanged"
                        >
                            <template #note>
                                The commission structure can be modified at any time in the future, with the flexibility
                                to add additional tiers as needed.
                            </template>
                        </radio-group>

                        <div class="-space-y-px" v-show="show.commission_type">
                            <radio-group
                                name="commission_type"
                                id="commission_type"
                                layout="horizontal"
                                model-value="percent"
                                :options="[ { value: 'percent', label: 'Percentage', description: 'Reward your partners with a percentage of the sale amount.'}, { value: 'static', label: 'Fixed amount', description: 'Reward your partners with a fixed commission.'},]"
                                @update:model-value="commission.type = $event">
                            </radio-group>
                        </div>
                        <div class="grid gap-y-6 gap-8 md:grid-cols-2" v-show="show.commission_value_and_period">
                            <div>
                                <label for="commission" class="block mb-1.5 text-sm">Commission and currency</label>
                                <div class="flex relative rounded">
                                    <span v-if="commission.type === 'percent'"
                                          class="inline-flex items-center px-5 text-gray-500 bg-gray-50 rounded-l border border-r-0 border-gray-300 sm:text-sm">%</span>
                                    <input type="text" name="commission" id="commission"
                                           :required="true"
                                           v-model="commission.amount"
                                           @update:model-value="commissionAmountChange"
                                           class="block w-full rounded-r border-gray-300 sm:text-sm focus:ring-0 focus:border-gray-400"
                                           :class="{ 'rounded-l': commission.type !== 'percent' }"
                                    />
                                    <div class="flex absolute inset-y-0 right-0 items-center">
                                        <label for="currency" class="sr-only">Currency</label>
                                        <select
                                            v-model="commission.currency"
                                            class="py-0 pr-7 pl-2 h-full text-gray-500 bg-transparent rounded border-transparent focus:ring-0 focus:border-gray-400 sm:text-sm">
                                            <option v-for="currency in props.currencies" :value="currency.code_upper">
                                                {{ currency.code_upper }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <small v-if="commission.type === 'percent'" class="text-gray-500 mt-1 block">
                                    The percentage of the sales amount allocated to each conversion, for example, 30
                                    percent.
                                </small>
                                <small v-if="commission.type === 'static'" class="text-gray-500 mt-1 block">
                                    The fixed portion of the sales amount designated for each conversion, such as $50.
                                </small>
                            </div>
                            <div>
                                <commission-period_setting
                                    ref="commission_period_setting_ref"
                                    @update:type="setCommissionPeriodType($event)"
                                    @update:value="commission.period_amount = $event"
                                ></commission-period_setting>
                            </div>
                        </div>
                    </form-section>
                </form-layout>
            </div>

            <div class="bg-gray-50 px-10 py-4 text-right rounded-b sticky bottom-0 z-40">
                <btn button-type="button" type="link" class="mr-6" @click="showStep(1)">Back</btn>
                <btn button-type="submit" type="primary" @click="create" :loading="loading.creating">Create</btn>
            </div>
        </div>
    </div>

</template>

<script setup>

import {ref, onBeforeMount} from 'vue';

window.addEventListener('beforeunload', (event) => {

    const navigationEntries = performance.getEntriesByType('navigation');
    const navigationType = navigationEntries.length > 0 ? navigationEntries[0].type : '';

    if (navigationType !== 'reload' || step.value === 1) {
        localStorage.removeItem('prtnr_affp_spod');
    }
});

onBeforeMount(() => {
    const _data = getStorageItem('data');
    const _step = getStorageItem('step');

    if (_data !== null) {
        data.value = _data;
    }

    if (_step !== null) {
        step.value = _step;
    }
})

const props = defineProps({
    currencies: {
        type: Object,
        required: true
    }
});

// refs
const commission_period_setting_ref = ref(null);

const step = ref(1);
const errors = ref([]);
const loading = ref({
    creating: false,
    validatingStepOne: false
});
const defaultPredefinedCommissionOption = '1';
const predefinedCommissionOption = ref(defaultPredefinedCommissionOption);

const defaultOptionsConfig = {
    '1': {
        label: '30% commission for a lifetime',
        description: 'Partners earn 30% commission on every customer payment for as long as they remain subscribed.'
    },
    '2': {
        label: '$100 for every new subscription sale',
        description: 'Affiliates receive a flat $100 for each new customer they refer.'
    },
    '3': {
        label: '200% of the customer’s first monthly payment',
        description: 'Partners earn double the customer’s first monthly payment as commission.'
    },
    '4': {
        label: '80% commission on the first two months',
        description: 'Affiliates take home 80% of the revenue from their referrals’ first two months.'
    },
    '5': {label: 'I would like to define the commission myself', description: ''},
};

const optionsConfig = ref({
    '1': {
        label: '30% commission for a lifetime',
        description: 'Partners earn 30% commission on every customer payment for as long as they remain subscribed.'
    },
    '2': {
        label: '$100 for every new subscription sale',
        description: 'Affiliates receive a flat $100 for each new customer they refer.'
    },
    '3': {
        label: '200% of the customer’s first monthly payment',
        description: 'Partners earn double the customer’s first monthly payment as commission.'
    },
    '4': {
        label: '80% commission on the first two months',
        description: 'Affiliates take home 80% of the revenue from their referrals’ first two months.'
    },
    '5': {label: 'I would like to define the commission myself', description: ''},
});

const data = ref({
    program_name: null,
    landing_page_url: null,
    subdomain: null,
});

const commission = ref({
    type: null,
    amount: null,
    currency: 'USD',
    period_type: null,
    period_amount: null
});

const show = ref({
    commission_type: false,
    commission_value_and_period: false,
});

const commissionAmountChange = (value) => {

    if (predefinedCommissionOption.value === '1') {
        optionsConfig.value['1'].label = defaultOptionsConfig['1'].label.replace('30%', value + '%');
    }

    if (predefinedCommissionOption.value === '2') {
        optionsConfig.value['2'].label = defaultOptionsConfig['2'].label.replace('$100', '$' + value);
    }
};

const showStep = (stepValue) => {
    step.value = stepValue;
}

const setStorageData = () => {
    localStorage.setItem('prtnr_affp_spod', JSON.stringify({
        data: data.value,
        step: step.value
    }));
}

const getStorageItem = (item) => {
    const storageData = JSON.parse(localStorage.getItem('prtnr_affp_spod'));

    if (storageData === null || item === null) {
        return storageData
    }

    return storageData[item] ?? null;
}

const validateStepOne = () => {
    errors.value = [];

    if (loading.value.validatingStepOne === true) {
        return;
    }
    loading.value.validatingStepOne = true;

    axios.post(route('app_int.programs.create.type.validate.affiliate'), {
        step: step.value,
        data: data.value
    })
        .then((response) => {
            const status = parseInt(response.data.status ?? -1);
            if (status === 1) {
                showStep(2);
                setStorageData();
            }

            if (status === 0 && response.data.errors) {
                displayErrors(response.data.errors);
            }
        })
        .catch((error) => {
            if (error.response.data.errors) {
                displayErrors(error.response.data.errors);
            }
        })
        .finally(() => loading.value.validatingStepOne = false);
}

const create = () => {

    errors.value = [];

    if (loading.value.creating === true) {
        return;
    }
    loading.value.creating = true;

    axios.post(route('app_int.programs.create.type.store.affiliate'), {
        commission: commission.value,
        data: data.value
    })
        .then((response) => {
            const status = parseInt(response.data.status ?? -1);
            if (status === 1) {
                localStorage.removeItem('prtnr_affp_spod');
                window.location = response.data.redirect_url;
            }

            if (status === 0 && response.data.errors) {
                displayErrors(response.data.errors);
            }
        })
        .catch((error) => {
            if (error.response.data.errors) {
                displayErrors(error.response.data.errors);
            }
        })
        .finally(() => loading.value.creating = false);
}

const displayErrors = (errs) => {

    let dErrors = [];
    if (typeof errs === 'object') {
        for (const property in errs) {
            dErrors = dErrors.concat(errs[property]);
        }
    }

    errors.value = dErrors;
};

const predefinedCommissionOptionChanged = (option) => {

    predefinedCommissionOption.value = option;

    if (option === '1') {

        show.value.commission_type = false;
        show.value.commission_value_and_period = true;

        commission.value.amount = 30;
        commission.value.type = 'percent';

        commission_period_setting_ref.value.changeValue(30);
        commission_period_setting_ref.value.changeType('lifetime');


    }

    if (option === '2') {

        commission.value.amount = 100;
        commission.value.type = 'static';

        commission_period_setting_ref.value.changeType('lifetime');

        show.value.commission_type = false;
        show.value.commission_value_and_period = true;


    }

    if (option === '3') {

        show.value.commission_type = false;
        show.value.commission_value_and_period = true;

        commission.value.amount = 200;
        commission.value.type = 'percent';

        commission_period_setting_ref.value.changeValue(1);
        commission_period_setting_ref.value.changeType('times');


    }

    if (option === '4') {

        show.value.commission_type = false;
        show.value.commission_value_and_period = true;

        commission.value.amount = 80;
        commission.value.type = 'percent';

        commission_period_setting_ref.value.changeValue(2);
        commission_period_setting_ref.value.changeType('months');


    }

    if (option === '5') {

        setCommissionSettingsToDefault();

        show.value.commission_type = true;
        show.value.commission_value_and_period = true;
    }

    optionsConfig.value['1'].label = defaultOptionsConfig['1'].label;
    optionsConfig.value['1'].description = defaultOptionsConfig['1'].description;
    optionsConfig.value['2'].label = defaultOptionsConfig['2'].label;
    optionsConfig.value['2'].description = defaultOptionsConfig['2'].description;
    optionsConfig.value['3'].label = defaultOptionsConfig['3'].label;
    optionsConfig.value['3'].description = defaultOptionsConfig['3'].description;
    optionsConfig.value['4'].label = defaultOptionsConfig['4'].label;
    optionsConfig.value['4'].description = defaultOptionsConfig['4'].description;
    optionsConfig.value['5'].label = defaultOptionsConfig['5'].label;
    optionsConfig.value['5'].description = defaultOptionsConfig['5'].description;
}

const setCommissionSettingsToDefault = () => {
    commission.value.amount = 30;
    commission.value.type = 'percent';
    commission_period_setting_ref.value.changeValue(30);
    commission_period_setting_ref.value.changeType('lifetime');
}

const setCommissionPeriodType = (event) => {
    if (typeof event === 'string') {
        commission.value.period_type = event;
    } else {
        commission.value.period_type = event.target.value;
    }
}

</script>

<style scoped>

</style>
