<template>
    <form-section title="Transactions" description="Improve security and optimize settings for transactions.">
        <toggle
            title="Allow self-referring sales"
            name="fraud_protection_allow_self_referring_email"
            v-model="fraud_protection_allow_self_referring_email_value">
            <template v-if="programType === 'refer-a-friend'" #description>
                If enabled, referring customers using the same email address as the referral will be able to make sales.
            </template>
            <template v-else #description>
                If enabled, customers using the same email address as the partner will be able to make sales.
            </template>
        </toggle>
        <div>
            <toggle
                title="Prevent multiple transactions"
                name="fraud_block_multiple_customer_transactions_per_interval"
                v-model="fraud_block_multiple_customer_transactions_per_interval_value"
            >
                <template #description>
                    If enabled, customers will be restricted from making multiple transactions within a specified time
                    period.
                </template>
            </toggle>
            <text-field
                v-if="fraud_block_multiple_customer_transactions_per_interval_value"
                placeholder="E.g. 1"
                class="w-1/2 mt-4 ml-16"
                name="fraud_block_multiple_customer_transactions_per_interval_seconds"
                v-model="fraud_block_multiple_customer_transactions_per_interval_seconds_value"
            >
                <template #note>Set the time period in seconds.</template>
            </text-field>
        </div>
    </form-section>
</template>

<script setup>

import {ref} from "vue";

const props = defineProps({
    programType: {
        type: String,
        default: 'affiliate'
    },
    settings: {
        type: Object,
        required:true,
        default: {
            fraud_protection_allow_self_referring_email_value: false,
            fraud_block_multiple_customer_transactions_per_interval_value: false,
            fraud_block_multiple_customer_transactions_per_interval_seconds_value: 1,
        }
    },
})

const fraud_protection_allow_self_referring_email_value = ref(props.settings.fraud_protection_allow_self_referring_email_value);
const fraud_block_multiple_customer_transactions_per_interval_value = ref(props.settings.fraud_block_multiple_customer_transactions_per_interval_value);
const fraud_block_multiple_customer_transactions_per_interval_seconds_value = ref(props.settings.fraud_block_multiple_customer_transactions_per_interval_seconds_value);

</script>

<style scoped>

</style>
