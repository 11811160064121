<template>
    <div>
        <draggable-accordion
            class="mb-2"
            v-for="content in props.data.contents"
            :unique_id="content.unique_id"
            :edit="content.edit"
            @remove="removeContent"
            @toggle="content.edit = !content.edit"
        >
            <template #title>{{ contentName }}</template>
            <template #content>
                <div class="grid lg:grid-cols-2 gap-6 gap-y-4">

                    <div class="col-span-2">
                        <label class="flex justify-between items-center mb-1.5 text-sm">
                            Title
                        </label>
                        <input
                            type="text"
                            v-model="content.title"
                            class="block w-full text-sm rounded border-gray-300 focus:border-gray-400 focus:ring-0"
                        />
                    </div>

                    <div class="col-span-2">
                        <label class="flex justify-between items-center mb-1.5 text-sm">
                            Image
                        </label>
                        <ResourceFileUpload
                            :unique_id="content.unique_id"
                            :data="content.files.content_block_image"
                            accept="image/*"
                            title="Image"
                            name="content_block_image"
                            no_src="https://via.placeholder.com/300x100/E5E7EB/9CA3AF?text=img"
                            @uploaded="(data) => content.files.content_block_image = data"
                            @deleted="(data) => content.files.content_block_image = data"
                        ></ResourceFileUpload>

                        <toggle
                            title="Resize image for retina display compatibility"
                            name="retina_image"
                            size="medium"
                            class="mt-4"
                            v-model="content.retina_friendly_image"
                        >
                        </toggle>

                    </div>

                    <div class="col-span-2">
                        <text-editor
                            :text="content.text"
                            @input="(newValue) => { content.text = newValue }"
                        />
                    </div>

                    <div>
                        <label
                            class="flex justify-between items-center mb-1.5 text-sm">
                            Button
                        </label>

                        <input
                            type="text"
                            v-model="content.button"
                            placeholder=""
                            class="block w-full text-sm rounded border-gray-300 focus:border-gray-400 focus:ring-0"
                        />
                    </div>

                    <div>
                        <label
                            class="flex justify-between items-center mb-1.5 text-sm">
                            URL
                        </label>

                        <input
                            type="text"
                            v-model="content.url"
                            placeholder="https://"
                            class="block w-full text-sm rounded border-gray-300 focus:border-gray-400 focus:ring-0"
                        />
                    </div>

                </div>
            </template>
        </draggable-accordion>

        <div class="flex justify-between items-center">
            <btn type="secondary" size="small" icon-name="add-circle" @click="addContent">Add {{ contentName }}</btn>
            <div class="flex justify-between items-center">
                <toggle
                    name="columns_toggle"
                    size="medium"
                    v-model="data.display_blocks_in_columns"
                />
                <span class="flex flex-col flex-grow" id="columns_toggle-label">
                    <span class="text-sm text-gray-900">Display blocks in columns</span>
                </span>
            </div>
        </div>

    </div>
</template>

<script setup>
import {defineProps} from 'vue';
import {uniqueId} from '../helpers';
import ResourceFileUpload from "./ResourceFileUpload.vue";

const contentName = 'Content block';

const props = defineProps({
    data: Object
});

props.data.display_blocks_in_columns = props.data?.display_blocks_in_columns ?? false;
props.data.contents = props.data?.contents ?? [];
props.data.contents.retina_friendly_image = props.data?.contents?.retina_friendly_image ?? false;

const addContent = () => {
    props.data.contents.push({
        unique_id: uniqueId(),
        edit: true,
        files: {
            content_block_image: {}
        },
        title: '',
        text: '',
        button: '',
        url: '',
    });
}

const removeContent = (uniqueId) => {
    props.data.contents.splice(
        props.data.contents.findIndex(content => content.unique_id === uniqueId)
    , 1);
}
</script>
