<template>
    <div>
        <label
            v-if="label"
            class="block text-sm mb-1.5"
        >
            {{ label }}
        </label>

        <div class="relative flex">
            <div v-if="prepend" class="inline-flex items-center px-5 text-gray-500 bg-gray-50 rounded-l border border-r-0 border-gray-300 sm:text-sm">
                {{ prepend }}
            </div>
            <div v-if="show_reveal_password_icon" @click="revealPassword"
                 class="absolute cursor-pointer bottom-0 flex items-center my-auto right-4 top-0 z-30">
                <svg v-if="myType === 'password'" class="w-5 h-5 text-gray-500" fill="currentColor"
                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                    <path fill="none" d="M0 0h24v24H0z"/>
                    <path
                        d="M9.342 18.782l-1.931-.518.787-2.939a10.988 10.988 0 0 1-3.237-1.872l-2.153 2.154-1.415-1.415 2.154-2.153a10.957 10.957 0 0 1-2.371-5.07l1.968-.359C3.903 10.812 7.579 14 12 14c4.42 0 8.097-3.188 8.856-7.39l1.968.358a10.957 10.957 0 0 1-2.37 5.071l2.153 2.153-1.415 1.415-2.153-2.154a10.988 10.988 0 0 1-3.237 1.872l.787 2.94-1.931.517-.788-2.94a11.072 11.072 0 0 1-3.74 0l-.788 2.94z"/>
                </svg>
                <svg v-else class="w-5 h-5 text-gray-500" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 24 24" width="24" height="24">
                    <path fill="none" d="M0 0h24v24H0z"/>
                    <path
                        d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm0 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm0 3a5 5 0 1 1-4.78 3.527A2.499 2.499 0 0 0 12 9.5a2.5 2.5 0 0 0-1.473-2.28c.466-.143.96-.22 1.473-.22z"/>
                </svg>
            </div>
            <input
                ref="inputFocus"
                class="transition-shadow block w-full rounded focus:ring-0"
                :placeholder="placeholder"
                :class="[
                  [sizing[size].input],
                  disabled ? 'cursor-not-allowed bg-gray-100 bg-opacity-75 text-gray-900 focus:border-gray-300' : '',
                  error ? 'border-red-400' : 'border-gray-300 focus:border-gray-400',
                  prepend ? 'rounded-l-none' : '',
                  fieldClass
                 ]"
                :id="fieldId"
                :type="myType"
                :name="name"
                :required="required"
                :maxlength="maxlength"

                :value="modelInternal"
                @input="validateInput"
                :disabled="disabled"
                :readonly="readonly"
            >
            <slot name="append" />
        </div>

        <small v-if="$slots.note" class="text-gray-500 mt-1 block">
            <slot name="note" />
        </small>

        <span
            v-if="error && errorMessage"
            class="block text-red-400 tracking-wide mt-1"
            :class="[
                [sizing[size].error],
            ]"
        >
            {{ errorMessage }}
        </span>
    </div>
</template>

<script>
export default {
    emits: ['update:modelValue'],
    props: {
        prefixes: {
            default: undefined,
            type: Array
        },
        placeholder: {
            default: '',
        },
        disabled: {
            default: false,
        },
        size: {
            default: 'default',
        },
        type: {
            default: 'text',
        },
        required: {
            default: false
        },
        show_reveal_password_icon: {
            default: false
        },
        fieldId: '',
        modelValue: '',
        label: {
            default: false
        },
        error: {
            default: false,
        },
        errorMessage: '',
        name: '',
        prepend: '',
        autofocus: {
            default: false,
        },
        maxlength: {
            default: ''
        },
        fieldClass: {
            default: '',
        },
        readonly: {
            default: false
        }
    },
    data() {
        return {
            myType: this.type,
            modelInternal: this.modelValue,
            sizing: {
                huge: {input: 'py-4 px-5 text-sm', error: 'text-sm'},
                large: {input: 'py-3 px-4 text-sm', error: 'text-sm'},
                medium: {input: 'py-2.5 px-3.5 text-sm', error: 'text-xs'},
                default: {input: 'py-2 px-3 text-sm', error: 'text-xs'},
                small: {input: 'py-1.5 px-1.5 text-xs', error: 'text-xs'},
            },
        }
    },
    watch: {
        modelValue (newValue) {
            this.modelInternal = newValue
        }
    },
    mounted() {
        if (this.autofocus) {
            const InputFocusRef = this.$refs.inputFocus;
            InputFocusRef.focus();
        }
    },
    methods: {
        revealPassword() {
            this.myType = this.myType === 'password' ? 'text' : 'password';
        },

        validateInput($event) {
            let value = $event.target.value;

            if (this.prefixes && value) {
                let valid = false;

                this.prefixes.forEach((prefix) => {
                    if (value.indexOf(prefix) === 0 || prefix.indexOf(value) === 0) {
                        valid = true;
                    }
                });

                if (!valid) {
                    value = this.prefixes[0] + value;
                }
            }

            this.modelInternal = value;
            this.$emit('update:modelValue', value);
        }
    }
}
</script>

<style scoped>

</style>
